import { createContext } from "react"
import { TrajectModel } from "../../domain/traject-utils"

export type FilterTraject = {
    traject: TrajectModel,
    setTraject: (traject: TrajectModel) => void
}

export const FilterContext = createContext<FilterTraject>({
    traject: { naam: 'Alles tonen', gemeente: 'Alle trajecten' },
    setTraject: () => {}
})