import { DocumentReference } from "firebase/firestore"
import getAllEvaluatieDocuments, { getEvaluatiesByTalentId as _getEvaluatiesByTalentId } from "../datasource/evaluatie-utils"
import { getTalentById } from "../datasource/talent-utils"
import { getUserById, UserModel } from "./user-utils"

export type EvaluatieModel = {
  aanmaak_datum: Date,
  auteur: {
    naam: string,
    profiel_foto?: string
  },
  content: string
}

export const getAllEvaluaties = async () => {
  const list: EvaluatieModel[] = []

  const evaluaties = await getAllEvaluatieDocuments()

  const users: Map<string, UserModel> = new Map()

  for (let evaluatie of evaluaties) {
    let _auteur
    if (evaluatie.geschreven_door){
    if (users.has(evaluatie.geschreven_door.id)) {
      _auteur = users.get(evaluatie.geschreven_door.id)
    } else {
      _auteur = await getUserById(evaluatie.geschreven_door)
      users.set(evaluatie.geschreven_door.id, _auteur)
    }}
    list.push({
      aanmaak_datum: evaluatie.aanmaak_datum,
      auteur: {
        naam: `${_auteur?.voornaam} ${_auteur?.achternaam}`,
        profiel_foto: _auteur?.profiel_foto
      },
      content: evaluatie.omschrijving
    })
  }

  return list

}

export const getEvaluatiesByTalentId = async (reference: DocumentReference) => {
  const list: EvaluatieModel[] = []

  var evaluaties = await _getEvaluatiesByTalentId(reference)

  const users: Map<string, UserModel> = new Map()

  for (let evaluatie of evaluaties) {
    let _auteur
    if (evaluatie.geschreven_door){
    if (users.has(evaluatie.geschreven_door.id)) {
      _auteur = users.get(evaluatie.geschreven_door.id)
    } else {
      _auteur = await getUserById(evaluatie.geschreven_door)
      users.set(evaluatie.geschreven_door.id, _auteur)
    }}
    list.push({
      aanmaak_datum: evaluatie.aanmaak_datum,
      auteur: {
        naam: `${_auteur?.voornaam} ${_auteur?.achternaam}`,
        profiel_foto: _auteur?.profiel_foto
      },
      content: evaluatie.omschrijving
    })
  }

  return list;
}

export const getEvaluatieWithAdditionalInfo = async (reference: DocumentReference) => {
  const list: EvaluatieModel[] = []
  const evaluaties = await _getEvaluatiesByTalentId(reference)

  for (let evaluatie of evaluaties) {
    var _auteur = await getUserById(evaluatie.geschreven_door)
    list.push({
      aanmaak_datum: evaluatie.aanmaak_datum,
      auteur: {
        naam: `${_auteur?.voornaam} ${_auteur?.achternaam}`,
        profiel_foto: _auteur?.profiel_foto
      },
      content: evaluatie.omschrijving
    })
  }

  return list;
}