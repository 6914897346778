import {
  multiFactor,
  TotpMultiFactorGenerator,
  TotpSecret,
  getAuth,
  signInWithEmailAndPassword,
  getMultiFactorResolver,
  MultiFactorError,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "./firebase";
import { FirebaseError } from "firebase/app";

const DISPLAY_NAME = "Bloeiindex"

export type AuthenticatorInfo = {
  secret_key: TotpSecret,
  qr_display: string
}

export const enrollMFA = async (totpSecret: TotpSecret, code: string) => {
  if (!auth.currentUser)
    return null
  const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(totpSecret, code)
  await multiFactor(auth.currentUser).enroll(multiFactorAssertion, DISPLAY_NAME)
}

export const unEnrollMFA = async () => {

}

export const displayMutliFactor = async () => {
  if (!auth.currentUser)
    return null
  const multiFactorSession = await multiFactor(auth.currentUser).getSession();
  const totpSecret = await TotpMultiFactorGenerator.generateSecret(
    multiFactorSession
  );

  const totpUri = totpSecret.generateQrCodeUrl(
    auth.currentUser!.email!,
    DISPLAY_NAME
  )

  return { secret_key: totpSecret, qr_display: totpUri } as AuthenticatorInfo
}

export const signInWithMFA = async (error: MultiFactorError, code: string) => {
  const mfaResolver = getMultiFactorResolver(getAuth(), error);
  // const enrolledFactors = mfaResolver.hints.map(info => info.displayName);
  const otpFromAuthenticator = code
  const multiFactorAssertion =
    TotpMultiFactorGenerator.assertionForSignIn(
      mfaResolver.hints[0].uid,
      otpFromAuthenticator
    );
  await mfaResolver.resolveSignIn(
    multiFactorAssertion
  );
}
