import { BellIcon } from '@heroicons/react/24/outline'

import {
  AcademicCapIcon,
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  BanknotesIcon,
  Bars3Icon,
  CheckBadgeIcon,
  CheckIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
  NewspaperIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusSmallIcon,
  ReceiptRefundIcon,
  UsersIcon,
  EnvelopeIcon,
  PhoneIcon,
  PlusIcon,
} from '@heroicons/react/20/solid'
import { PaperClipIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { TalentModel, getTalents, getTalentWithAdditionalInfo, getTalentByIdWithAdditionalInfo, getTalentsByTraject } from "../../domain/talent-utils"
import { Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogBackdrop, DialogPanel, } from '@headlessui/react'
import { CodeBracketIcon, EllipsisVerticalIcon, FlagIcon, StarIcon } from '@heroicons/react/20/solid'
import Layout from '../../layout'
import { Link, useHref } from 'react-router-dom'
import Details from '../talentenhub/caseload/details/App'
import Rapportage from './rapportagedocument/App'
import TrajectTab from '../../components/traject/App'
import OpleidenTab from '../../components/opleiden/App'
import RapportagesTab from '../../components/rapportages/App'
import RoosterTab from '../../components/rooster/App'
import TalentTab from '../../components/talent/App'
import Filter from "../../components/filter/App"
import { EvaluatieModel, getEvaluatiesByTalentId } from '../../domain/evaluatie-utils'
// import { PlusSmallIcon } from '@heroicons/react/20/solid'
import Talenten from '../../components/subnav/talenten/talenten'
import { useNavigate } from 'react-router-dom'
import DefaultImage from '../../components/defaultimage/defaultimage'
import { FilterContext, FilterTraject } from '../../components/context/FilterContext'
import { TrajectModel } from '../../domain/traject-utils'
import { RapportageModel, createRapportage, getRapportageByTalentRef, getRapportages } from '../../domain/rapportage-utils'
import { BedrijfModel } from '../../domain/bedrijf-utils'
import { getGenerativeModel } from 'firebase/vertexai-preview'
import { vertexAI } from '../../utils/firebase'
import Acties from '../../components/modal/acties/Acties'
import { useAuth } from '../../context/AuthContext'
import dayjs from 'dayjs'
import { getIntakeById } from '../../domain/intake-utils'



const tabs = [
  { name: 'Talent', href: '#', current: true },
  { name: 'Traject', href: "#", current: false },
  { name: 'Opleiden', href: '/caseload/details', current: false },
  { name: 'Documenten', href: '/talentenhub/rapportage', current: false },
  { name: 'Rooster', href: '/rooster', current: false },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const { id } = useParams<{ id: string }>();
  const [selectedPerson, setSelectedPerson] = useState<TalentModel | null>(null)
  const [talenten, setTalenten] = useState<TalentModel[]>([])
  const [tabContent, setTabContent] = useState(0)
  const [evaluaties, setEvaluaties] = useState<EvaluatieModel[]>([])
  const navigate = useNavigate();
  const [selected, setSelected] = useState<TrajectModel>()
  const [bedrijvenModel, setBedrijvenModel] = useState<BedrijfModel | null>(null);
  const [rapportages, setRapportages] = useState<RapportageModel[]>([])
  const [openNieuw, setOpenNieuw] = useState(false)
  const [open, setOpen] = useState(false)

  const [loading, setLoading] = useState(false)

  const { user } = useAuth()


  const handlePersonClick = (person) => {
    if (person.id == selectedPerson?.id) return
    getTalentByIdWithAdditionalInfo(person.ref).then((response) => {
      setSelectedPerson(response);
      navigate(`/talentenhub`);
    })
  }

  const fetchTalentsAndSelectedPerson = () => {
    getTalentWithAdditionalInfo(100).then((response) => {
      setTalenten(response);
      setSelectedPerson(response[0]);
    })
  };

  const fetchEvaluaties = async (talent) => {
    const _evaluaties = await getEvaluatiesByTalentId(talent.ref);
    setEvaluaties(_evaluaties);
  };

  const fetchRapportages = async (talent) => {
    const rapports = await getRapportageByTalentRef(talent);
    setRapportages(rapports);
  };

  useEffect(() => {
    fetchTalentsAndSelectedPerson();
  }, [id]);

  useEffect(() => {

    // if (!selectedPerson) return
    // fetchEvaluaties(selectedPerson?.ref);
    // fetchRapportages(selectedPerson);


    setTabContent(0);
  }, [selectedPerson]);

  useEffect(() => {
    setLoading(true)
    switch (tabContent) {
      case 1:
        fetchEvaluaties(selectedPerson)
        break;
    }
    setLoading(false)
  }, [tabContent])

  const actions = [
    {
      title: 'Roosterwijziging',
      href: '#',
      icon: ClockIcon,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
    },
    {
      title: 'Nieuwe rapportage',
      href: '#',
      icon: CheckBadgeIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },
    {
      title: 'Afspraak maken',
      href: '#',
      icon: UsersIcon,
      iconForeground: 'text-sky-700',
      iconBackground: 'bg-sky-50',
    },
    {
      title: 'Intake starten',
      href: '/talentenhub/intakeformulier',
      icon: NewspaperIcon,
      iconForeground: 'text-yellow-700',
      iconBackground: 'bg-yellow-50',
    },
    {
      title: 'Update schrijven',
      href: '#',
      icon: PencilSquareIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },
    {
      title: 'Opleiding activeren',
      href: '#',
      icon: AcademicCapIcon,
      iconForeground: 'text-indigo-700',
      iconBackground: 'bg-indigo-50',
    },
  ]

  useEffect(() => {
    if (selected && selected.naam != "Alles tonen") {
      getTalentsByTraject(selected).then((talenten) => {
        //setTalenten(talenten)
        // setSelected(talenten[0])
        setTalenten(talenten);
        if (talenten.length > 0) {
          setSelectedPerson(talenten[0]);
        } else {
          setSelectedPerson(null);
        }
      }

      )
    } else {
      fetchTalentsAndSelectedPerson()
    }

  }, [selected]
  )


  function getRender(selectedPerson) {
    tabs[0].current = false
    tabs[1].current = false
    tabs[2].current = false
    tabs[3].current = false
    tabs[4].current = false
    tabs[tabContent].current = true
    switch (tabContent) {
      case 0:
        if (!selectedPerson)
          return null
        return TalentTab(selectedPerson)
      case 1:
        return TrajectTab(evaluaties)
      case 2:
        return OpleidenTab()
      case 3:
        return RapportagesTab(rapportages)
      case 4:
        return RoosterTab()

    }

  }

  function generateRapportage() {
    Promise.all([getEvaluatiesByTalentId(selectedPerson?.ref!), getIntakeById(selectedPerson!.intakeRef!.id)]).then(([evaluaties, intake]) => {
      const prompt = `Schrijf een uitgebreid voortgangsrapport in HTML formatting met tailwindcss op basis van de informatie die voor hand is van minimaal 400 en maximaal 700 woorden voor het Talent in kwestie. Het rapport moet een 360-graden beeld geven van de prestaties van het Talent. Verwerk in de tekst een zelfreflectie-instrument zoals het Dariuz model om de persoonlijke evaluatie van het Talent vast te leggen. Geef mogelijke aanbevelingen voor persoonlijke ontwikkeling, gebaseerd op de verzamelde data. Structureer het rapport als volgt: Begin met een inleiding, refereer hier naar het begin tot nu toe en opvallende punten in de rapportage, maak een uitgeschreven zelfreflectie middels het Dariuz model zonder bullets, maak een uitgeschreven samenvatting van de wervloerfeedback tot nu toe  zonder bullets, schrijf een samenvatting over de mogelijk behaalde competenties en sluit af met een totaal samenvatting en een evetuele conclusie. Zorg dat het doorlopende teksten zijn zonder bullets.`
      const data = "\n Op basis van de volgende informatie: evaluaties: " + JSON.stringify(evaluaties) + " en de intake: " + JSON.stringify(intake) + " en tot slot de persoonsgegevens: " + JSON.stringify(selectedPerson)
      const finalPrompt = prompt + data
      console.log(finalPrompt)
      const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

      model.generateContent(finalPrompt).then((result) => {
        const response = result.response;
        const text = response.text();
        createRapportage({
          auteur_id: user!.ref.id,
          bedrijf_id: selectedPerson!.lopend_traject!.werkbedrijf!.reference.id,
          talent_id: selectedPerson!.ref.id,
          traject_id: selectedPerson!.traject!.reference!.id,
          leidinggevende_id: selectedPerson!.lopend_traject!.werkvloer_begeleider_ref!.id,
          consulent_id: selectedPerson!.lopend_traject!.consulent!.ref.id,
          content: text,
          soort: `${selectedPerson?.voornaam}_1e_Rapportage_${dayjs().format("DD-MM-YYYY-HH:mm")}`,
          startDatum: new Date()
        })
      })
    })

  }

  return (
    <Layout >

      <Talenten />

      <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-4 pt-3 h-[calc(100vh-115px)] overscroll-none">


        {/* Talenten */}
        <aside className="grid grid-cols-1 gap-4 lg:col-span-1 overflow-y-auto h-[calc(100vh-125px)] content-start">
          <FilterContext.Provider value={{ traject: selected!, setTraject: setSelected }}>
            <Filter />
          </FilterContext.Provider>
          <ul role="list" className="divide-y divide-gray-100">
            {talenten.map((person) => (
              <li
                key={person.email}
                className="relative flex justify-between px-4 py-5 hover:bg-gray-50 cursor-pointer"
                onClick={() => handlePersonClick(person)}
              >
                <div className="flex min-w-0 gap-x-4 content-center">
                  <DefaultImage profiel_foto={person.foto} className="h-12 w-12 flex-none rounded-full bg-gray-50 self-center" />
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900 truncate">{person.voornaam} {person.achternaam}</p>
                    <p className="text-xs pt-1 leading-4 text-gray-900">{person.traject?.naam}</p>
                    <p className="text-sm leading-4 text-gray-400">Vestiging talent</p>
                  </div>
                </div>
                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-start ">

                </div>
              </li>
            ))}
          </ul>
        </aside>

        {/* Header */}
        <main className="grid grid-cols-1 gap-4 lg:col-span-3 h-svh content-start ">
          {selectedPerson ? (
            <div>
              {/* <div>
                <DefaultImage
                  profiel_foto={selectedPerson.foto} className="h-32 w-full object-cover lg:h-48" />
              </div> */}
              <div className="mx-auto  px-4 sm:px-6 lg:px-8 ">
                <div className="sm:flex sm:items-start sm:space-x-5 ">
                  <div className="flex">
                    <DefaultImage
                      profiel_foto={selectedPerson.foto} className="h-24 w-24 rounded-lg ring-4 ring-white sm:h-32 sm:w-32" />
                  </div>


                  <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                    <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
                      <h1 className="truncate text-2xl font-bold text-gray-900">{selectedPerson.voornaam} {selectedPerson.achternaam}</h1>
                    </div>
                    <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        <PhoneIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" />
                        <a
                          href={`tel:${selectedPerson.telefoonnummer}`}>
                          <span>Bellen</span>
                        </a>
                      </button>
                      <button
                        type="button"
                        // 'selectedPerson.popup${person.id
                        onClick={(e) => setOpenNieuw(true)}
                        className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500 shadow-sm ring-1 ring-inset ring-pink-300 hover:bg-gray-50 "
                      >
                        <NewspaperIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5 text-pink-400 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500 " />
                        <a>
                          <span>Document Genereren</span>
                          <Acties open={openNieuw} setOpen={setOpenNieuw} onclick={generateRapportage} />
                        </a>
                      </button>
                    </div>
                  </div>


                </div>
                <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
                  <h1 className="truncate text-2xl font-bold text-gray-900">{selectedPerson.voornaam} {selectedPerson.achternaam}</h1>
                </div>

              </div>


              {/* Tabbar nav */}
              <div className='px-6 '>
                <div className="mt-4 ">
                  <div className="sm:hidden">

                    {/* <label htmlFor="current-tab" className="sr-only">
                    Select a tab
                  </label> */}

                    <select
                      id="current-tab"
                      name="current-tab"
                      className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                    >
                      {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden sm:block">
                    <nav className="-mb-px flex space-x-8">
                      {tabs.map((tab, i) => (
                        <a
                          key={tab.name}
                          onClick={(e) => { setTabContent(i) }}
                          aria-current={tab.current ? 'page' : undefined}
                          className={classNames(
                            tab.current
                              ? 'border-sky-400 text-sky-400'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                          )}
                        >
                          {tab.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
            </div>

          ) : null}


          <div className='px-8 h-[calc(100vh-320px)] overflow-y-auto'>
            {(loading) ? <div>Loading</div> : (getRender(selectedPerson))}
          </div>
        </main>
      </div>
    </Layout>
  )
}