import { collection, query, getDocs, where, limit, DocumentReference, doc, getDoc, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { db } from "../utils/firebase";
export type RemoteBedrijf = {
    reference: DocumentReference,
    data_eigenaar_ref?: DocumentReference,
    huisnummer?: string,
    logo?: string,
    naam?: string,
    plaats?: string,
    postcode?: string,
    soort?: string,
    straat?: string
};

const bedrijfConverter = {
    toFirestore: (bedrijf: RemoteBedrijf) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteBedrijf {
            const data = snapshot.data(options)!;
            return {
              reference: snapshot.ref,
              data_eigenaar_ref: data.data_eigenaar_ref,
              huisnummer: data.huisnummer,
              logo: data.logo,
              naam: data.naam,
              plaats: data.plaats,
              postcode: data.postcode,
              soort: data.soort,
              straat: data.straat,
            }
        }
  }

export default async () => {

    const q = query(collection(db, "bedrijf")).withConverter(bedrijfConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteBedrijf[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}

export const getAllBedrijvenDocuments = async (ref: DocumentReference): Promise<RemoteBedrijf[]> => {
    const q = query(collection(db, "bedrijf"), where('data_eigenaar_ref', '==', ref)).withConverter(bedrijfConverter);
    const querySnapshot = await getDocs(q);

    const list: RemoteBedrijf[] = [];
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list;
};

export const getBedrijfDocumentByReference = async (id: string) => {
    const reference = await doc(db, "bedrijf", id).withConverter(bedrijfConverter)
    const docSnap = await getDoc(reference);

    if (!docSnap.exists()) return null

    return docSnap.data()
};

