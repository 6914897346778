import React from 'react';

const BarChart: React.FC = () => {
  // Sample data: each day has two values (e.g., "Money spent" and "Conversion rate")
  const data = [
    { day: 'Abeer', blueValue: 50, peachValue: 60 },
    { day: 'Abou', blueValue: 30, peachValue: 20 },
    { day: 'Abraham', blueValue: 70, peachValue: 90 },
    { day: 'Adnan', blueValue: 100, peachValue: 80 },
    { day: 'Ahmed', blueValue: 40, peachValue: 120 },
    { day: 'Aksinia', blueValue: 80, peachValue: 110 },
    { day: 'Alda', blueValue: 60, peachValue: 70 },
    { day: 'Ali', blueValue: 50, peachValue: 90 },
    { day: 'Antonio', blueValue: 30, peachValue: 40 },
    { day: 'Babu', blueValue: 70, peachValue: 90 },
    { day: 'Bashar', blueValue: 100, peachValue: 80 },
    { day: 'Bladimir', blueValue: 40, peachValue: 160 },
    { day: 'Borah', blueValue: 80, peachValue: 110 },
    { day: 'Brenda', blueValue: 60, peachValue: 70 },
    { day: 'Brian', blueValue: 50, peachValue: 200 },
    { day: 'Cady', blueValue: 30, peachValue: 40 },
    { day: 'Caroline', blueValue: 70, peachValue: 90 },
    { day: 'Cherlison', blueValue: 100, peachValue: 80 },
    { day: 'Daisy', blueValue: 40, peachValue: 120 },
    { day: 'Danny', blueValue: 80, peachValue: 110 },
    { day: 'Dave', blueValue: 60, peachValue: 70 },
    { day: 'Eline', blueValue: 50, peachValue: 60 },
    { day: 'Erhan', blueValue: 30, peachValue: 40 },
    { day: 'Erica', blueValue: 70, peachValue: 90 },
    { day: 'Thu', blueValue: 100, peachValue: 80 },
    { day: 'Fri', blueValue: 40, peachValue: 120 },
    { day: 'Sat', blueValue: 80, peachValue: 110 },
    { day: 'Sun', blueValue: 60, peachValue: 70 },
    { day: 'Mon', blueValue: 50, peachValue: 60 },
    { day: 'Tue', blueValue: 30, peachValue: 40 },
    { day: 'Wed', blueValue: 70, peachValue: 90 },
    { day: 'Thu', blueValue: 100, peachValue: 80 },
    { day: 'Fri', blueValue: 40, peachValue: 120 },
    { day: 'Sat', blueValue: 80, peachValue: 110 },
    { day: 'Sun', blueValue: 60, peachValue: 70 },
    { day: 'Mon', blueValue: 50, peachValue: 60 },
    { day: 'Tue', blueValue: 30, peachValue: 40 },
    { day: 'Wed', blueValue: 70, peachValue: 90 },
    { day: 'Thu', blueValue: 100, peachValue: 80 },
    { day: 'Fri', blueValue: 40, peachValue: 120 },
    { day: 'Sat', blueValue: 80, peachValue: 110 },
    { day: 'Sun', blueValue: 60, peachValue: 70 },
  ];

  return (
    <div className="flex flex-col  text-gray-200">


      {/* Bar chart */}
      <div className="flex space-x-4">
        {data.map((item, index) => (
          <div key={index} className="flex flex-col items-center self-end">
            <div className="flex space-x-1 ">
              {/* Blue bar */}
              <div
                className="bg-slate-200 w-4 rounded-t-md self-end"
                style={{ height: `${item.blueValue}px` }}
              ></div>
              {/* Peach bar */}
              <div
                className="bg-sky-600  w-4 rounded-t-md self-end"
                style={{ height: `${item.peachValue}px` }}
              ></div>
            </div>
            <span className="mt-2 text-sm text-gray-400">{item.day}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChart;