import { debug } from 'webpack';
import _getTalents, { getTalentById, RemoteTalent, getTalentenWithUitstroom as _getTalentenWithUitstroom, getTalentDocumentsByTraject } from '../datasource/talent-utils';
import { DocumentReference } from 'firebase/firestore';
import { getTrajectByID } from '../datasource/traject-utils';
import { getBedrijfByReference } from './bedrijf-utils';
import { getLopendTrajectByReference, LopendTrajectModel } from './lopend-traject-utils';
import { getTrajectById, TrajectModel } from './traject-utils';

export type TalentModel = {
  id: string;
  ref: DocumentReference,
  gemeente?: string,
  huisnummer?: string,
  nationaliteit?: string,
  plaats?: string,
  postcode?: string,
  straat?: string,
  telefoonnummer: string,
  voornaam: string,
  achternaam: string,
  volledige_naam: string,
  foto?: string,
  email: string,
  rol: string,
  traject?: TrajectModel | null,
  lopend_traject?: LopendTrajectModel | null
  consulentenRef?: DocumentReference;
  intakeRef?: DocumentReference
}

function convertRemoteToModel(remote: RemoteTalent) {
  if (!remote) return null

  return {
    id: remote.ref.id,
    ref: remote.ref,
    gemeente: remote.talent?.gemeente,
    huisnummer: remote.talent?.huisnummer,
    nationaliteit: remote.talent?.nationaliteit,
    plaats: remote.talent?.plaats,
    postcode: remote.talent?.postcode,
    straat: remote.talent?.straat,
    telefoonnummer: remote.telefoon_nummer,
    voornaam: remote.voornaam,
    achternaam: remote.achternaam,
    volledige_naam: `${remote.voornaam} ${remote.achternaam}`,
    foto: remote.profiel_foto,
    email: remote.email,
    rol: remote.gebruikers_rol
  } as TalentModel
}

export const getTalents = async (max: number) => {
  const list: TalentModel[] = []

  var talenten = await _getTalents(max)
  talenten.forEach((talent) => {
    list.push({
      id: talent.ref.id,
      ref: talent.ref,
      gemeente: talent.talent?.gemeente,
      huisnummer: talent.talent?.huisnummer,
      nationaliteit: talent.talent?.nationaliteit,
      plaats: talent.talent?.plaats,
      postcode: talent.talent?.postcode,
      straat: talent.talent?.straat,
      telefoonnummer: talent.telefoon_nummer,
      voornaam: talent.voornaam,
      achternaam: talent.achternaam,
      volledige_naam: talent.voornaam + " " + talent.achternaam,
      foto: talent.profiel_foto,
      email: talent.email,
      rol: talent.gebruikers_rol,

    })
  })

  return list;
}

/* Get "Werkvloer begeleider" door specfiek te zoeken naar die rol*/
export const getTalentWithDiffrentRole = async (max: number, rol: string) => {
  /* GET TalentModel
     Filter voor andere rollen
     en return de value */
     const allTalents = await getTalents(max);
     const filteredTalents = allTalents.filter(talent => talent.rol === rol);
     return filteredTalents;
}


export const getTalentByIdWithAdditionalInfo = async (id: string | DocumentReference) => {
  const talent = await getTalentById(id);

  if (!talent) {
    throw new Error(`Talent met ID ${id.toString()} niet gevonden`);
  }

 /* traject ophahalen voor talent 
Talent model aanmaaken op basis van gegevns van talent en traject */

let traject = await getTrajectById(talent.talent?.traject_ref.id)
let lopendTraject = await getLopendTrajectByReference(talent.talent?.lopendtraject_ref)

  return {
    id: talent.ref.id,
    ref: talent.ref,
    gemeente: talent.talent?.gemeente,
    huisnummer: talent.talent?.huisnummer,
    nationaliteit: talent.talent?.nationaliteit,
    plaats: talent.talent?.plaats,
    postcode: talent.talent?.postcode,
    straat: talent.talent?.straat,
    telefoonnummer: talent.telefoon_nummer,
    voornaam: talent.voornaam,
    achternaam: talent.achternaam,
    volledige_naam: talent?.voornaam + " " + talent?.achternaam,
    foto: talent.profiel_foto,
    email: talent.email,
    rol: talent.gebruikers_rol,
    traject: traject,
    lopend_traject: lopendTraject,
    intakeRef: talent.talent?.intake_ref
  };
};

export const getTalentWithAdditionalInfo = async (max: number, includeData = false) => {

  /* Get talent list
     Get traject from talent ID
     en return it's value*/
  const list: TalentModel[] = []
  const talenten = await _getTalents(max)

  const trajecten: Map<string, TrajectModel> = new Map()

  for(var talent of talenten) {
    let traject
    if (talent.talent?.traject_ref)
    {
      if (trajecten.has(talent.talent.traject_ref.id)) {
        traject = trajecten.get(talent.talent.traject_ref.id)
      } else {
        traject = await getTrajectByID(talent.talent?.traject_ref)
        trajecten.set(talent.talent.traject_ref.id, traject)
      }
    }
    let lopendTraject: LopendTrajectModel | null = {id: talent.talent?.lopendtraject_ref?.id}
    
    if (includeData)
      lopendTraject = await getLopendTrajectByReference(talent.talent?.lopendtraject_ref)
    list.push({
      id: talent.ref.id,
      ref: talent.ref,
      gemeente: talent.talent?.gemeente,
      huisnummer: talent.talent?.huisnummer,
      nationaliteit: talent.talent?.nationaliteit,
      plaats: talent.talent?.plaats,
      postcode: talent.talent?.postcode,
      straat: talent.talent?.straat,
      telefoonnummer: talent.telefoon_nummer,
      voornaam: talent.voornaam,
      achternaam: talent.achternaam,
      volledige_naam: talent.voornaam + " " + talent.achternaam,
      foto: talent.profiel_foto,
      email: talent.email,
      rol: talent.gebruikers_rol,
      traject: traject,
      lopend_traject: lopendTraject
    })
  }

  return list;

}

//functie om talent op te halen op basis van speciek traject
export const getTalentsByTraject = async (trajectModel: TrajectModel, includeData = false) => {

  const list: TalentModel[] = []

  var talenten = await getTalentDocumentsByTraject(trajectModel.reference!)

  for(let talent of talenten) {

    let lopendTraject: LopendTrajectModel | null = {id: talent.talent?.lopendtraject_ref?.id}
    
    if (includeData)
      lopendTraject = await getLopendTrajectByReference(talent.talent?.lopendtraject_ref)

    list.push({
      id: talent.ref.id,
      ref: talent.ref,
      gemeente: talent.talent?.gemeente,
      huisnummer: talent.talent?.huisnummer,
      nationaliteit: talent.talent?.nationaliteit,
      plaats: talent.talent?.plaats,
      postcode: talent.talent?.postcode,
      straat: talent.talent?.straat,
      telefoonnummer: talent.telefoon_nummer,
      voornaam: talent.voornaam,
      achternaam: talent.achternaam,
      volledige_naam: `${talent.voornaam} ${talent.achternaam}`,
      foto: talent.profiel_foto,
      email: talent.email,
      rol: talent.gebruikers_rol,
      traject: trajectModel,
      lopend_traject: lopendTraject,
      intakeRef: talent.talent?.intake_ref
    })
  }
  

  return list;

}

export const getTalentenWithUitstroom = async () => {
  const talenten = await _getTalentenWithUitstroom()
  const list: TalentModel[] = []
  for (const talent of talenten) {
    let traject = await getTrajectByID(talent.talent?.traject_ref)
    list.push({
      id: talent.ref.id,
      ref: talent.ref,
      gemeente: talent.talent?.gemeente,
      huisnummer: talent.talent?.huisnummer,
      nationaliteit: talent.talent?.nationaliteit,
      plaats: talent.talent?.plaats,
      postcode: talent.talent?.postcode,
      straat: talent.talent?.straat,
      telefoonnummer: talent.telefoon_nummer,
      voornaam: talent.voornaam,
      achternaam: talent.achternaam,
      volledige_naam: talent.voornaam + " " + talent.achternaam,
      foto: talent.profiel_foto,
      email: talent.email,
      rol: talent.gebruikers_rol,
      traject: {
        naam: traject?.naam,
      }

    })
  }

  return list;
}

export default () => { }

//Get talent by Id
export const getTalentsById = async (id: string | DocumentReference): Promise<TalentModel | null> => {
  try {
    const talent = await getTalentById(id);

    if (!talent) {
      console.warn(`Talent with ID ${id.toString()} not found.`);
      return null;
    }

    return {
      id: talent.ref.id,
      ref: talent.ref,
      gemeente: talent.talent?.gemeente,
      huisnummer: talent.talent?.huisnummer,
      nationaliteit: talent.talent?.nationaliteit,
      plaats: talent.talent?.plaats,
      postcode: talent.talent?.postcode,
      straat: talent.talent?.straat,
      telefoonnummer: talent.telefoon_nummer, 
      voornaam: talent.voornaam,
      achternaam: talent.achternaam,
      volledige_naam: `${talent.voornaam ?? ''} ${talent.achternaam ?? ''}`.trim(),
      foto: talent.profiel_foto,
      email: talent.email,
      rol: talent.gebruikers_rol,
    };

  } catch (error) {
    console.error(`Error fetching talent by ID ${id.toString()}:`, error);
    return null;
  }
};


// Check van het lopend_traject Model of een talent een werkbedrijf_ref heeft zo ja vraag van dat bedrijf de locatie op, 
// locatie is een combinatie van "naam", "postcode", "straat" en "plaats"

// export const getWerkbedrijfLocatie = async (lopendTraject: LopendTrajectModel) => {
//   if (lopendTraject.werkbedrijf_ref) {
//     console.log(lopendTraject.werkbedrijf_ref)
//     const bedrijf = await getBedrijfByReference(lopendTraject.werkbedrijf_ref);
//     if (bedrijf) {
//       return {
//         naam: bedrijf.naam,
//         postcode: bedrijf.postcode,
//         straat: bedrijf.straat,
//         plaats: bedrijf.plaats,
//         locatie: bedrijf.plaats + " " + bedrijf.straat + " " + bedrijf.postcode,
//       };
//     }
//   }
//   return null; 
// };
