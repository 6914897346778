import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import Layout from '../../../layout'
import Talenten from '../../../components/subnav/talenten/talenten'
import { getIntakes, IntakeModel } from '../../../domain/intake-utils'
import { useEffect, useState } from 'react'
import { Timestamp } from 'firebase/firestore';


const statuses = {
    Nieuw: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}
// const projects = [
//     {
//         id: 1,
//         name: 'Selcheley Michiel', // -> data_eigenaar_ref
//         href: '/talentenhub/intakeformulier',
//         status: 'Nieuw',
//         createdBy: 'Leslie Alexander',  // -> uitgevroed_door_ref
//         dueDate: 'March 17, 2023',      // -> datum_uitgevoerd -> Date
//         dueDateTime: '2023-03-17T00:00Z', // -> datum_uitgevoerd -> Time
//     },
//     {
//         id: 2,
//         name: 'New benefits plan',
//         href: '#',
//         status: 'Nieuw',
//         createdBy: 'Leslie Alexander',
//         dueDate: 'May 5, 2023',
//         dueDateTime: '2023-05-05T00:00Z',
//     },
//     {
//         id: 3,
//         name: 'Onboarding emails',
//         href: '#',
//         status: 'In progress',
//         createdBy: 'Courtney Henry',
//         dueDate: 'May 25, 2023',
//         dueDateTime: '2023-05-25T00:00Z',
//     },
//     {
//         id: 4,
//         name: 'iOS app',
//         href: '#',
//         status: 'In progress',
//         createdBy: 'Leonard Krasner',
//         dueDate: 'June 7, 2023',
//         dueDateTime: '2023-06-07T00:00Z',
//     },
//     {
//         id: 5,
//         name: 'Marketing site redesign',
//         href: '#',
//         status: 'Archived',
//         createdBy: 'Courtney Henry',
//         dueDate: 'June 10, 2023',
//         dueDateTime: '2023-06-10T00:00Z',
//     },
// ]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Intakes() {
    const [intakes, setIntakes] = useState<IntakeModel[]>([])
    useEffect(() => {

        getIntakes(5, {consulent: true}).then((response) => {

            return setIntakes(response)

        })

    }, [])


    return (
        <Layout>

<Talenten/>
             <ul role="list" className="mx-auto max-w-7xl divide-y divide-gray-100">
                {intakes.map((intake) => (
                    <li key={""} className="flex items-center justify-between gap-x-6 py-5">
                        <div className="min-w-0">
                            <div className="flex items-start gap-x-3">
                                {/* intake.naam van de talent, inplaats van voorstel. intake.data_eigenaar_ref */}
                                <p className="text-sm font-semibold leading-6 text-gray-900">{(intake.voornaam && intake.achternaam) ? `${intake.voornaam} ${intake.achternaam}` : "Onbekend"}</p>
                                <p
                                    className={classNames(
                                        // statuses[project.status],
                                        'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                                    )}
                                >
                                    {/* intake.status */}
                                    {/* {intake?.} */}
                                </p>
                            </div>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <p className="whitespace-nowrap">
                                    Binnengekomen op {intake?.datumUitgevoerd?.toLocaleDateString('nl-NL')}
                                </p>
                                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                    <circle r={1} cx={1} cy={1} />
                                </svg>
                                <p className="truncate">Door: {intake.consulent?.naam}</p>

                            </div>
                        </div>
                        <div className="flex flex-none items-center gap-x-4">
                            <a
                                href={"./intakedocument/" + intake.id }
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                            >
                                Bekijk intake<span className="sr-only">, {intake.voorstel}</span>
                            </a>
                            <Menu as="div" className="relative flex-none">
                                <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                    <span className="sr-only">Open options</span>
                                    <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
                                </MenuButton>
                                <MenuItems
                                    transition
                                    className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    <MenuItem>
                                        <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                                            Edit<span className="sr-only">, {intake.voorstel}</span>
                                        </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                                            Move<span className="sr-only">, {intake.voorstel}</span>
                                        </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                                            Delete<span className="sr-only">, {intake.voorstel}</span>
                                        </a>
                                    </MenuItem>
                                </MenuItems>
                            </Menu>
                        </div>
                    </li>
                ))}
            </ul>
        </Layout>
    )
}