import { collection, query, getDocs, getDoc, where, limit, DocumentReference, setDoc, doc, QueryDocumentSnapshot, SnapshotOptions, addDoc, orderBy } from "firebase/firestore";
import { db } from "../utils/firebase";
import { IntakeModel, NewIntakeModel } from "../domain/intake-utils";

// gemeente?: string,
//     trajectId?: string,
//     startDatum?: Date,
//     consulentId?: string,
//     eersteIndrukTalent?: string,
//     documenten?: File[],
//     voornaam?: string,
//     achternaam?: string,
//     geboortedatum?: Date,
//     gender?: string,
//     email?: string,
//     telefoon?: string,
//     nationaliteit?: string,
//     afkomst?: string,
//     straat?: string,
//     huisnummer?: string,
//     postcode?: string,
//     woonplaats?: string,
//     situatieSchets?: string,
//     werkervaring?: string,
//     soortUitkering?: string,
//     vervoer?: string,
//     taalniveau?: string,
//     gezondheid?: string,
//     opleiding?: string,
//     medicijnen?: string,
//     ingeburgerd?: number,
//     allergien?: string,
//     fysiekeBelasting?: string,
//     diplomas?: string,
//     diplomasGewaardeerd?: boolean,
//     kinderopvang?: boolean,
//     rijbewijs?: boolean,
//     ervaringTalent?: string,
//     ambitieTalent?: string,
//     kunnenTalent?: string,
//     voorstel?: string

export type RemoteIntake = {
    reference: DocumentReference,
    data_eigenaar_ref: DocumentReference,
    gemeente: string,
    traject_reference?: DocumentReference,
    start_datum?: Date,
    datum_uitgevoerd?: Date,
    consulent_reference?: DocumentReference,
    eerste_indruk_talent: string,
    documenten: string[],
    voornaam: string,
    achternaam: string,
    geboortedatum: Date,
    geslacht: string,
    email: string,
    telefoon: string,
    nationaliteit: string,
    afkomst: string,
    straat: string,
    huisnummer: string,
    postcode: string,
    woonplaats: string,
    korte_situatieschets: string,
    werkervaring: string,
    soort_uitkering: string,
    vervoer: string,
    taalniveau: string,
    gezondheid: string,
    opleiding: string,
    medicijnen: string,
    ingeburgerd: boolean,
    allergien: string,
    fysieke_belasting: string,
    diplomas: string,
    diplomas_gewaardeerd: boolean,
    kinderopvang: boolean,
    rijbewijs: boolean,
    talent_ervaring: string,
    talent_wensen: string,
    talent_kunnen: string,
    voorstel: string
};

const intakeConverter = {
    toFirestore: (intake: RemoteIntake) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteIntake {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            data_eigenaar_ref: data.data_eigenaar_ref,
            gemeente: data.gemeente,
            traject_reference: data.traject_reference,
            start_datum: data.start_datum?.toDate(),
            datum_uitgevoerd: data.datum_uitgevoerd?.toDate(),
            consulent_reference: data.consulent_reference,
            eerste_indruk_talent: data.eerste_indruk_talent,
            documenten: data.documenten,
            voornaam: data.voornaam,
            achternaam: data.achternaam,
            geboortedatum: data.geboortedatum?.toDate(),
            geslacht: data.geslacht,
            email: data.email,
            telefoon: data.telefoon,
            nationaliteit: data.nationaliteit,
            afkomst: data.afkomst,
            straat: data.straat,
            huisnummer: data.huisnummer,
            postcode: data.postcode,
            woonplaats: data.woonplaats,
            korte_situatieschets: data.korte_situatieschets,
            werkervaring: data.werkervaring,
            soort_uitkering: data.soort_uitkering,
            vervoer: data.vervoer,
            taalniveau: data.taalniveau,
            gezondheid: data.gezondheid,
            opleiding: data.opleiding,
            medicijnen: data.medicijnen,
            ingeburgerd: data.ingeburgerd,
            allergien: data.allergien,
            fysieke_belasting: data.fysieke_belasting,
            diplomas: data.diplomas,
            diplomas_gewaardeerd: data.diplomas_gewaardeerd,
            kinderopvang: data.kinderopvang,
            rijbewijs: data.rijbewijs,
            talent_ervaring: data.talent_ervaring,
            talent_wensen: data.talent_wensen,
            talent_kunnen: data.talent_kunnen,
            voorstel: data.voorstel
        }
    }
}

export default async () => {
    const q = query(collection(db, "intake"), orderBy("datum_uitgevoerd", "desc")).withConverter(intakeConverter);
    const querySnapshot = await getDocs(q);
    const list: RemoteIntake[] = [];

    querySnapshot.forEach((doc) => {
        list.push(doc.data());
    });

    return list;
}

export const getIntakeDocumentById = async (id: string) => {
    const _doc = doc(db, "intake", id).withConverter(intakeConverter);
    const docSnap = await getDoc(_doc);

    if (docSnap.exists()) {
        return docSnap.data()
    }
}

export const getIntakeByRef = async (intakeRef: DocumentReference) => {
    console.log("test")
    const docSnap = await getDoc(intakeRef);

    if (docSnap.exists()) {
        return {
            reference: docSnap.ref,
            data_eigenaar_ref: docSnap.data().data_eigenaar_ref,
            gemeente: docSnap.data().gemeente,
            traject_reference: docSnap.data().traject_reference,
            start_datum: docSnap.data().start_datum?.toDate(),
            datum_uitgevoerd: docSnap.data().datum_uitgevoerd?.toDate(),
            consulent_reference: docSnap.data().consulent_reference,
            eerste_indruk_talent: docSnap.data().eerste_indruk_talent,
            documenten: docSnap.data().documenten,
            voornaam: docSnap.data().voornaam,
            achternaam: docSnap.data().achternaam,
            geboortedatum: docSnap.data().geboortedatum?.toDate(),
            geslacht: docSnap.data().geslacht,
            email: docSnap.data().email,
            telefoon: docSnap.data().telefoon,
            nationaliteit: docSnap.data().nationaliteit,
            afkomst: docSnap.data().afkomst,
            straat: docSnap.data().straat,
            huisnummer: docSnap.data().huisnummer,
            postcode: docSnap.data().postcode,
            woonplaats: docSnap.data().woonplaats,
            korte_situatieschets: docSnap.data().korte_situatieschets,
            werkervaring: docSnap.data().werkervaring,
            soort_uitkering: docSnap.data().soort_uitkering,
            vervoer: docSnap.data().vervoer,
            taalniveau: docSnap.data().taalniveau,
            gezondheid: docSnap.data().gezondheid,
            opleiding: docSnap.data().opleiding,
            medicijnen: docSnap.data().medicijnen,
            ingeburgerd: docSnap.data().ingeburgerd,
            allergien: docSnap.data().allergien,
            fysieke_belasting: docSnap.data().fysieke_belasting,
            diplomas: docSnap.data().diplomas,
            diplomas_gewaardeerd: docSnap.data().diplomas_gewaardeerd,
            kinderopvang: docSnap.data().kinderopvang,
            rijbewijs: docSnap.data().rijbewijs,
            talent_ervaring: docSnap.data().talent_ervaring,
            talent_wensen: docSnap.data().talent_wensen,
            talent_kunnen: docSnap.data().talent_kunnen,
            voorstel: docSnap.data().voorstel
        }
    }
    return null
}
export const createIntake = async (model: NewIntakeModel, traject: DocumentReference, consulent: DocumentReference, documenten: string[]) => {
    await addDoc(collection(db, "intake"), {
            gemeente: model.gemeente,
            traject_reference: traject,
            start_datum: model.startDatum,
            datum_uitgevoerd: new Date(),
            consulent_reference: consulent,
            eerste_indruk_talent: model.eersteIndrukTalent,
            documenten: documenten,
            voornaam: model.voornaam,
            achternaam: model.achternaam,
            geboortedatum: model.geboortedatum,
            geslacht: model.gender,
            email: model.email,
            telefoon: model.telefoon,
            nationaliteit: model.nationaliteit,
            afkomst: model.afkomst,
            straat: model.straat,
            huisnummer: model.huisnummer,
            postcode: model.postcode,
            woonplaats: model.woonplaats,
            korte_situatieschets: model.situatieSchets,
            werkervaring: model.werkervaring,
            soort_uitkering: model.soortUitkering,
            vervoer: model.vervoer,
            taalniveau: model.taalniveau,
            gezondheid: model.gezondheid,
            opleiding: model.opleiding,
            medicijnen: model.medicijnen,
            ingeburgerd: model.ingeburgerd,
            allergien: model.allergien,
            fysieke_belasting: model.fysiekeBelasting,
            diplomas: model.diplomas,
            diplomas_gewaardeerd: model.diplomasGewaardeerd,
            kinderopvang: model.kinderopvang,
            rijbewijs: model.rijbewijs,
            talent_ervaring: model.ervaringTalent,
            talent_wensen: model.ambitieTalent,
            talent_kunnen: model.kunnenTalent,
            voorstel: model.voorstel
    });


}
