import { PlusSmallIcon } from '@heroicons/react/20/solid'

export default function Example() {
  return (
    <div>
        <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8 py- border-b border-gray-200 pb-5">
          <h1 className="text-base font-semibold leading-7 text-gray-900">Bijenkorf</h1>
          <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7 ">
            <a href="/bijenkorf" className="text-sky-400 hover:text-sky-400">
              Alle Talenten
            </a>
            <a href="/bijenkorf/collegas" className="text-gray-700 hover:text-sky-400">
              Collega's
            </a>
            <a href="/bijenkorf/bedrijven" className="text-gray-700 hover:text-sky-400">
              Bedrijven
            </a>
           
            
          </div>

        </div>
      </div>
  )
}
