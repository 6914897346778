// dit is de layout file

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import TalentPage from "./pages/talentenhub/page";
import { Dialog, DialogPanel } from '@headlessui/react';
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon, Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/20/solid';
// Caseload
import Caseload from './pages/talentenhub/caseload/App';
import Details from './pages/talentenhub/caseload/details/App';
import States from './pages/talentenhub/caseload/details/App';

// Talentenhub
import Rapportage from './pages/talentenhub/rapportagedocument/App';
import Rapportages from './pages/talentenhub/rapportages/App';
import JobProfile from './pages/talentenhub/intakeformulier/App';
import Aanmeldingen from './pages/talentenhub/aanmeldingen/App';
import Settings from './pages/beheer/beveiliging/App';
import Intakes from './pages/talentenhub/intakes/app';
import Detail from './pages/talentenhub/rapportages/detail/App'
import Aanmeldingformulier from './pages/talentenhub/aanmeldingformulier/Aanmeldingformulier'

// Bijenkorf
import Bijenkorf from './pages/bijenkorf/App';
import Collegas from './pages/bijenkorf/collegas/App';
import Bedrijven from './pages/bijenkorf/bedrijven/App';


// Beheer
import Algemeen from './pages/beheer/App';
import Beveiliging from './pages/beheer/beveiliging/App';
import Talenten from './pages/beheer/talenten/App';
import Onboarding from './pages/beheer/onboarding/App';
import Intakedocument from './pages/talentenhub/intakedocument/App'
import Admin from './pages/admin/App'
import Trajectensettings from './pages/beheer/trajecten/App'
import Aanmeldingdocument from './pages/talentenhub/aanmeldingdocument/Aanmeldingdocument'
import Trajecten from './pages/trajecten/App'
import TrajectDetails from './pages/trajecten/detail/App'
import Organisatie from './pages/organisatie/App'



import Rooster, { } from './pages/rooster/App';



import Login from './pages/login/App';
import Auth from './pages/login/auth/App'
import { AuthProvider, useAuth } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Companies from './pages/admin/companies/App';
import Team from './pages/beheer/team/Team';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {/* Home */}
          <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />

          {/* Talentenhub */}
          <Route path="/talentenhub" element={<PrivateRoute><TalentPage /></PrivateRoute>} />
          <Route path="/talentenhub/rapportage" element={<PrivateRoute><Rapportage /></PrivateRoute>} />
          <Route path="/talentenhub/rapportages" element={<PrivateRoute><Rapportages /></PrivateRoute>} />
          <Route path="/talentenhub/intakeformulier" element={<PrivateRoute><JobProfile /></PrivateRoute>} />
          <Route path="/talentenhub/intakeformulier/:aanmeldingId" element={<PrivateRoute><JobProfile /></PrivateRoute>} />
          <Route path="/talentenhub/rapportageformulier" element={<PrivateRoute><Aanmeldingdocument /></PrivateRoute>} />
          <Route path="/talentenhub/intakes" element={<PrivateRoute><Intakes /></PrivateRoute>} />
          <Route path="/talentenhub/intakedocument/:id" element={<PrivateRoute><Intakedocument /></PrivateRoute>} />
          <Route path="/talentenhub/caseload" element={<PrivateRoute><Caseload /></PrivateRoute>} />
          <Route path="/talentenhub/aanmeldingen" element={<PrivateRoute><Aanmeldingen /></PrivateRoute>} />
          <Route path="/talentenhub/caseload/details/:id" element={<PrivateRoute><Details /></PrivateRoute>} />
          <Route path="/talentenhub/aanmeldingformulier" element={<PrivateRoute><Aanmeldingformulier /></PrivateRoute>} />
          <Route path="/talentenhub/rapportages/detail/:id" element={<PrivateRoute><Detail /></PrivateRoute>} />
          <Route path="/talentenhub/:id" element={<PrivateRoute><TalentPage /></PrivateRoute>} />

          {/* Rooster */}
          <Route path="/rooster" element={<PrivateRoute><Rooster /></PrivateRoute>} />

          {/* Trajecten */}
          <Route path="/trajecten/detail" element={<PrivateRoute><TrajectDetails /></PrivateRoute>} />
          <Route path="/trajecten" element={<PrivateRoute><Trajecten /></PrivateRoute>} />

          {/* Bijenkorf */}
          <Route path="/bijenkorf" element={<PrivateRoute><Bijenkorf /></PrivateRoute>} />
          <Route path="/bijenkorf/collegas" element={<PrivateRoute><Collegas /></PrivateRoute>} />
          <Route path="/bijenkorf/bedrijven" element={<PrivateRoute><Bedrijven /></PrivateRoute>} />

          {/* Admin */}
          <Route path="/admin" element={<PrivateRoute><Admin /></PrivateRoute>} />
          <Route path="/admin/companies" element={<PrivateRoute><Companies /></PrivateRoute>} />
          <Route path="/beheer/trajecten" element={<PrivateRoute><Trajectensettings /></PrivateRoute>} />
          <Route path="/organisatie" element={<PrivateRoute><Organisatie /></PrivateRoute>} />
          <Route path="/beheer" element={<PrivateRoute><Algemeen /></PrivateRoute>} />
          <Route path="/beheer/beveiliging" element={<PrivateRoute><Beveiliging /></PrivateRoute>} />
          <Route path="/beheer/talenten" element={<PrivateRoute><Talenten /></PrivateRoute>} />
          <Route path="/beheer/onboarding" element={<PrivateRoute><Onboarding /></PrivateRoute>} />
          <Route path="/beheer/team" element={<PrivateRoute><Team /></PrivateRoute>} />

          {/* Auth */}
          <Route path="/login" element={<Login />} />
          <Route path="/login/auth" element={<Auth />} />

        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode >

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();