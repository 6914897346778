import Layout from '../../../layout'
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import TalentDetail from './steps/TalentDetail'
import TalentProfiel from './steps/TalentProfiel'
import Stepper from '../../../components/multi-form/Stepper'
import StepperControl from '../../../components/multi-form/StepperControl'
import { StepperContext } from '../../../context/StepperContext'
import { IntakeModel, NewIntakeModel } from '../../../domain/intake-utils'
import { TalentModel } from '../../../domain/talent-utils'
import { useState, useEffect } from 'react'
import TalentConfirm from './steps/TalentConfirm'
import { useParams } from 'react-router-dom'
import { getAanmeldingById } from '../../../domain/aanmelding-utils'

const steps = [
  { id: 'Stap 1', name: 'Talent details', href: '#', status: 'current' },
  { id: 'Stap 2', name: 'Application form', href: '#', status: 'upcoming' },
  { id: 'Stap 3', name: 'Preview', href: '#', status: 'upcoming' },
  { id: 'Stap 4', name: 'Preview', href: '#', status: 'upcoming' },
]

export function JobPosting() {
  return (
    <div className=" pb-5 sm:flex sm:items-center sm:justify-between">
      <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
        <h3 className="ml-2 mt-2 text-base font-semibold leading-6 text-gray-900">Intake</h3>
        <p className="ml-2 mt-1 truncate text-sm text-gray-500">[Dude Goed]</p>
      </div>
      <div className="mt-3 flex sm:ml-4 sm:mt-0">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Annuleren
        </button>
        <button
          type="button"
          className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Opslaan
        </button>
      </div>


    </div>
  )
}


export default function Intake() {

  const [currentStep, setCurrentStep] = useState(1)
  const [data, setData] = useState<NewIntakeModel>({})

  let { aanmeldingId } = useParams();

  const steps = [
    'Talent details',
    'Talent profiel',
    'Controle'
  ]

  useEffect(() => {
    if (aanmeldingId) {
      console.log(aanmeldingId)
      getAanmeldingById(aanmeldingId).then((aanmelding) => {
        console.log(aanmelding)
        if (!aanmelding)return
        setData({...data, 
          voornaam: aanmelding.voornaam, 
          achternaam: aanmelding.achternaam, 
          afkomst: aanmelding.afkomst, 
          geboortedatum: aanmelding.geboortedatum,
          gender: aanmelding.geslacht,
          email: aanmelding.email,
          telefoon: aanmelding.telefoon,
          nationaliteit: aanmelding.nationaliteit,
          straat: aanmelding.straat,
          huisnummer: aanmelding.huisnummer,
          postcode: aanmelding.postcode,
          woonplaats: aanmelding.woonplaats,
          gemeente: aanmelding.gemeente,
          situatieSchets: aanmelding.situatie_schets,
          // consulentId: aanmelding.consulent.id,
          // trajectId: aanmelding.traject?.id
        })
      })
    }
  }, [])

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <TalentDetail handleClick={handleClick} />
      case 2:
        return <TalentProfiel handleClick={handleClick} />
      case 3:
        return <TalentConfirm />
    }
  }

  const handleClick = (direction) => {

    if (currentStep == steps.length && direction == "next") {

    } else {

      let newStep = currentStep

      direction == "next" ? newStep++ : newStep--;

      newStep > 0 && newStep <= steps.length && setCurrentStep(newStep)

    }
  }

  useEffect(() => {
    console.log(data)
  }, [data])

  return (
    <Layout>
      <div className='h-full overflow-auto'>
        <div>
          {JobPosting()}
        </div>


        <Stepper steps={steps} currentStep={currentStep}></Stepper>
        <StepperContext.Provider value={{ data, setData }}>
          {displayStep(currentStep)}
        </StepperContext.Provider>
        {/* <button onClick={(e) => {setStep((step) => ++step); console.log("test");e.stopPropagation()}}>Next</button>
            <button onClick={(e) => {setStep((step) => --step); e.stopPropagation()}}>Previous</button> */}
        <StepperControl handleClick={handleClick} currentStep={currentStep} steps={steps}></StepperControl>

      </div>

    </Layout>
  )
}
