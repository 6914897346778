import dayjs from "dayjs";
import { createAanmeldingDocument, getAanmeldingDocumentById, getAanmeldingDocuments } from "../datasource/aanmelding-utils";
import { getUserByReference } from "../datasource/user-utils";
import { getTrajectById } from "./traject-utils";
import { _getUserById, getUserById } from "./user-utils";

export type AanmeldingModel = {
    id: string,
    volledige_naam: string,
    voornaam: string,
    achternaam: string,
    geboortedatum: Date,
    geslacht: string,
    email: string,
    telefoon: string,
    nationaliteit: string,
    afkomst: string,
    straat: string,
    huisnummer: string,
    postcode: string,
    woonplaats: string,
    gemeente: string,
    situatie_schets: string,
    doelstellingen: string,
    bijzonderheden: string,
    consulent: {
        id?: string,
        volledige_naam: string
    },
    traject?: {
        id?: string
    }
    aanmaakDatum: Date
};

export type NewAanmeldingModel = {
    voornaam: string,
    achternaam: string,
    geboortedatum: Date,
    geslacht: string,
    email: string,
    telefoon: string,
    nationaliteit: string,
    afkomst: string,
    straat: string,
    huisnummer: string,
    postcode: string,
    woonplaats: string,
    gemeente: string,
    situatie_schets: string,
    doelstellingen: string,
    bijzonderheden: string,
    trajectId:  string,
    consulentId: string
};

export const getAanmeldingen = async () => {
    const list: AanmeldingModel[] = []
    const aanmeldingen = await getAanmeldingDocuments();

    for (let aanmelding of aanmeldingen) {
        let consulent = await getUserById(aanmelding.consulent_reference)

        list.push({
            id: aanmelding.reference.id,
            volledige_naam: `${aanmelding.voornaam} ${aanmelding.achternaam}`,
            voornaam: aanmelding.voornaam,
            achternaam: aanmelding.achternaam,
            geboortedatum: aanmelding.geboortedatum,
            geslacht: aanmelding.geslacht,
            email: aanmelding.email,
            telefoon: aanmelding.telefoon,
            nationaliteit: aanmelding.nationaliteit,
            afkomst: aanmelding.afkomst,
            straat: aanmelding.straat,
            huisnummer: aanmelding.huisnummer,
            postcode: aanmelding.postcode,
            woonplaats: aanmelding.woonplaats,
            gemeente: aanmelding.gemeente,
            situatie_schets: aanmelding.situatie_schets,
            doelstellingen: aanmelding.doelstellingen,
            bijzonderheden: aanmelding.bijzonderheden,
            consulent: {
                volledige_naam: `${consulent?.voornaam} ${consulent?.achternaam}`
            },
            aanmaakDatum: aanmelding.aanmaak_datum
        })
    }

    return list;
}

export const getAanmeldingById = async (id: string) => {

    const aanmelding = await getAanmeldingDocumentById(id)

    if (!aanmelding) return null

    let consulent = await getUserById(aanmelding.consulent_reference)

    return {
        id: aanmelding.reference.id,
        volledige_naam: `${aanmelding.voornaam} ${aanmelding.achternaam}`,
        voornaam: aanmelding.voornaam,
            achternaam: aanmelding.achternaam,
        geboortedatum: aanmelding.geboortedatum,
        geslacht: aanmelding.geslacht,
        email: aanmelding.email,
        telefoon: aanmelding.telefoon,
        nationaliteit: aanmelding.nationaliteit,
        afkomst: aanmelding.afkomst,
        straat: aanmelding.straat,
        huisnummer: aanmelding.huisnummer,
        postcode: aanmelding.postcode,
        woonplaats: aanmelding.woonplaats,
        gemeente: aanmelding.gemeente,
        situatie_schets: aanmelding.situatie_schets,
        doelstellingen: aanmelding.doelstellingen,
        bijzonderheden: aanmelding.bijzonderheden,
        consulent: {
            id: consulent?.ref.id,
            volledige_naam: `${consulent?.voornaam} ${consulent?.achternaam}`
        },
        traject: {
            id: aanmelding.traject_reference.id
        },
        aanmaakDatum: aanmelding.aanmaak_datum
    } as AanmeldingModel

}

export const createAanmelding = async (data: NewAanmeldingModel) => {

    const traject = await getTrajectById(data.trajectId)

    if (!traject) return Promise.reject("Geen traject gevonden")

    const consulent = await _getUserById(data.consulentId)

    if (!consulent) return Promise.reject("Geen consulent gevonden")

    return createAanmeldingDocument({
        voornaam: data.voornaam,
        achternaam: data.achternaam,
        geboortedatum: data.geboortedatum,
        geslacht: data.geslacht,
        email: data.email,
        telefoon: data.telefoon,
        nationaliteit: data.nationaliteit,
        afkomst: data.afkomst,
        straat: data.straat,
        huisnummer: data.huisnummer,
        postcode: data.postcode,
        woonplaats: data.woonplaats,
        gemeente: data.gemeente,
        situatie_schets: data.situatie_schets,
        doelstellingen: data.doelstellingen,
        bijzonderheden: data.bijzonderheden,
        traject_reference: traject.reference,
        consulent_reference: consulent.ref,
        aanmaak_datum: dayjs().toDate()
    })

}