import { DocumentReference } from 'firebase/firestore';
import _getTrajecten, { createTrajectDocument, getTrajectDocumentById, updateTrajectDocument } from '../datasource/traject-utils';
import { _getUserById, getUserById, UserModel } from './user-utils';
import { getCountAanmeldingenByTraject } from '../datasource/aanmelding-utils';
import { getCountTalentenByTraject } from '../datasource/talent-utils';

export type TrajectModel = {
    data_eigenaar_ref?: DocumentReference,
    duur?: number,
    aantal_rapportages?: number,
    evaluatie_momenten?: number | null,
    jobcoach_interventies?: number,
    praktijkverklaring?: boolean,
    talentportfolio?: boolean,
    loonwaardemeting?: boolean,
    gemeente?: string,
    naam?: string,
    omschrijving?: string,
    reference?: DocumentReference,
    traject_consulent_reference?: DocumentReference,
    consulent?: {
      volledige_naam: string
    },
    aanmeldingenCount?: number,
    talentenCount?: number,
};

export type NewTrajectModel = {
  data_eigenaar_ref?: DocumentReference,
    duur?: number,
    traject_consulent_id?: string,
    aantal_rapportages?: number,
    evaluatie_momenten?: number,
    jobcoach_interventies?: number,
    praktijkverklaring?: boolean,
    talentportfolio?: boolean,
    loonwaardemeting?: boolean,
    gemeente?: string,
    naam?: string,
    omschrijving?: string,
    consulent_reference?: DocumentReference
}

export const getAllTrajecten = async () => {
  const list: TrajectModel[] = []

  const trajecten = await _getTrajecten()

  for (var traject of trajecten) {
    list.push({
      data_eigenaar_ref: traject.data_eigenaar_ref,
      duur: traject.duur,
      traject_consulent_reference: traject.traject_consulent_reference,
      aantal_rapportages: traject.aantal_rapportages,
      evaluatie_momenten: traject.evaluatie_momenten,
      jobcoach_interventies: traject.jobcoach_interventies,
      praktijkverklaring: traject.praktijkverklaring,
      talentportfolio: traject.talentportfolio,
      loonwaardemeting: traject.loonwaardemeting,
      gemeente: traject.gemeente,
      naam: traject.naam,
      omschrijving: traject.omschrijving,
      reference: traject.reference,

  })
  }

  return list

}

export const getTrajectById = async (id?: string) => {

  if (!id) return null

  const traject = await getTrajectDocumentById(id)

  if (!traject) return null

  return {
    data_eigenaar_ref: traject.data_eigenaar_ref,
      duur: traject.duur,
      traject_consulent_reference: traject.traject_consulent_reference,
      aantal_rapportages: traject.aantal_rapportages,
      evaluatie_momenten: traject.evaluatie_momenten,
      jobcoach_interventies: traject.jobcoach_interventies,
      praktijkverklaring: traject.praktijkverklaring,
      talentportfolio: traject.talentportfolio,
      loonwaardemeting: traject.loonwaardemeting,
      gemeente: traject.gemeente,
      naam: traject.naam,
      omschrijving: traject.omschrijving,
      reference: traject.reference,
  }

}

export const getTrajecten = async () => {
    const list: TrajectModel[] = []
   
  var trajecten = await _getTrajecten()

  const consulenten: Map<string, UserModel> = new Map()

  for (let traject of trajecten) {
    let consulent
    if (traject.traject_consulent_reference)
    {
      if (consulenten.has(traject.traject_consulent_reference.id)) {
        consulent = consulenten.get(traject.traject_consulent_reference.id)
      } else {
        consulent = await getUserById(traject.traject_consulent_reference)
        consulenten.set(traject.traject_consulent_reference.id, consulent)
      }
    }

    let aanmeldingCount = await getCountAanmeldingenByTraject(traject.reference)
    let talentCount = await getCountTalentenByTraject(traject.reference)

    list.push({
      data_eigenaar_ref: traject.data_eigenaar_ref,
      duur: traject.duur,
      aantal_rapportages: traject.aantal_rapportages,
      evaluatie_momenten: traject.evaluatie_momenten,
      jobcoach_interventies: traject.jobcoach_interventies,
      praktijkverklaring: traject.praktijkverklaring,
      talentportfolio: traject.talentportfolio,
      loonwaardemeting: traject.loonwaardemeting,
      gemeente: traject.gemeente,
      naam: traject.naam,
      omschrijving: traject.omschrijving,
      reference: traject.reference,
      consulent: {
        volledige_naam: `${consulent.voornaam} ${consulent.achternaam}`
      },
      aanmeldingenCount: aanmeldingCount,
      talentenCount: talentCount
    })
  }

  return list;
}

export const createTraject = (data: NewTrajectModel) => {
  return createTrajectDocument(data)
}

export const updateTraject = async (id: string, consulent_id: string, data: NewTrajectModel) => {

  if (!consulent_id) return null

  const user = await _getUserById(consulent_id)

  if (!user) return null

  data.consulent_reference = user.ref

  return updateTrajectDocument(id, data)
}