import Layout from '../../../layout'
import { Fragment, useState } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingCartIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import Bijenkorf from '../../../components/subnav/bijenkorf/bijenkorf'
import { Link } from 'react-router-dom'




  const people = [
    {
      name: 'Dude Goed',
      title: 'Sebastiaan van den Berg',
      role: 'Operationeel Directeur',
      email: 'aanmelden@dudegoed.nl',
      telephone: '+1-202-555-0170',
      imageUrl:
        'https://sociaalondernemenhaaglanden.nl/wp-content/uploads/2024/03/Asset-2@4x.png',
        link: '/caseload/details',
    },
    // More people...
  ]

const tabs = [
    { name: 'Hardlopers', href: '#', current: true },
    { name: 'Nieuw', href: '#', current: false },
    { name: 'Bijna klaar', href: '#', current: false },
   
  ]
  
//   function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
//   }
  
  

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Pool() {
    const [open, setOpen] = useState(false)
  return (
    <Layout>
      <Bijenkorf/>

      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
    
      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-6">
      {people.map((person) => (
        <li
          key={person.email}
          className="col-span-1 flex flex-col  rounded-lg bg-white text-center shadow-2xl overflow-hidden"
        >
          <Link to={person.link}></Link>
          <div className="flex flex-1 flex-col p-8">
            <img alt="" src={person.imageUrl} className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" />
            <h3 className="mt-6 text-sm font-medium text-gray-900">{person.name}</h3>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <dt className="sr-only">Title</dt>
              <dd className="text-sm text-gray-500">{person.title}</dd>
              <dt className="sr-only">Role</dt>
              <dd className="mt-3">
                <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {person.role}
                </span>
              </dd>
            </dl>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <a
                  href={`mailto:${person.email}`}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <EnvelopeIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  Email
                </a>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <a
                  href={`tel:${person.telephone}`}
                  className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <PhoneIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  Call
                </a>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
    </div>
    </Layout>
  )
}