import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

const RapportagePDFFile = ({content}) => {
    if (!content)content = "<html></html>"
    return (<Document>
        <Page size="A4">
            <View>
                <Html>{content}</Html>
            </View>
        </Page>
    </Document>)
}

export default RapportagePDFFile