import { collection, query, getDocs, where, limit, DocumentReference, QueryDocumentSnapshot, SnapshotOptions, orderBy } from "firebase/firestore";
import { db } from "../utils/firebase";
export type RemoteEvaluatie = {
    data_eigenaar_ref: DocumentReference,
    aanmaak_datum: Date,
    geschreven_door: DocumentReference,
    omschrijving: string,
    talent_ref: DocumentReference
};

const evaluatieConverter = {
    toFirestore: (evaluatie: RemoteEvaluatie) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteEvaluatie {
        const data = snapshot.data(options)!;
        return {
            data_eigenaar_ref: data.data_eigenaar_ref,
            aanmaak_datum: data.aanmaak_datum.toDate(),
            geschreven_door: data.geschreven_door,
            omschrijving: data.omschrijving,
            talent_ref: data.talent_ref
        }
    }
}

export default async () => {

    const q = query(collection(db, "evaluatie"), orderBy("aanmaak_datum", "desc")).withConverter(evaluatieConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteEvaluatie[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}

export const getEvaluatiesByTalentId = async (reference: DocumentReference) => {

    const q = query(collection(db, "evaluatie"), where("talent_ref", "==", reference), orderBy("aanmaak_datum", "desc")).withConverter(evaluatieConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteEvaluatie[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}