import { ArrowDownIcon, EnvelopeIcon, PhoneIcon, ViewfinderCircleIcon } from '@heroicons/react/20/solid'
import { DocumentTextIcon } from '@heroicons/react/24/solid'

const people = [
  {
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  {
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },{
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },{
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },{
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },{
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },{
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },{
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },{
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },{
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },{
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },{
    name: 'James Baller',
    title: '1e Rapportage',
    role: 'Verstuurd',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  // More people...
]

// id: string,
//     aanmaakdatum: Date,
//     bedrijf_id?: string,
//     bedrijf?: {
//         naam?: string
//     }
//     consulent_id?: string,
//     consulent?: {
//         naam?: string
//     }
//     content: string,
//     leidinggevende_id?: string,
//     leidinggevende?: {
//         naam?: string
//     }
//     traject_id?: string,
//     traject?: {
//         naam?: string
//     },
//     talent_id?: string,
//     talent?: {
//         naam?: string
//     }
//     auteur_id?: string,
//     auteur?: {
//         naam?: string
//     }
//     soort: string,
//     startdatum: Date,

export default function Example({rapportages}) {
  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1">
      {rapportages.map((rapportage) => (
        <li key={rapportage.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-slate-50 ">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">{rapportage.talent?.naam}</h3>
                <span className="inline-flex shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  
                </span>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">{rapportage.soort}</p>
            </div>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <a
                  href={`/talentenhub/rapportages/detail/${rapportage.id}`}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <ArrowDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  Download
                </a>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <a
                  href={`/talentenhub/rapportages/detail/${rapportage.id}`}
                  className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <DocumentTextIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  Bekijken
                </a>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}
