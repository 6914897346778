export default function Example({aanmeldingen}) {
    return (
        <ul role="list" className="divide-y divide-gray-100">
            {aanmeldingen.map((aanmelding) => (
                <li key={aanmelding.id} className="py-4">
                    <div className="flex items-center gap-x-3">
                        <h3 className="flex-auto truncate text-sm/6 font-semibold text-gray-900">{aanmelding.volledige_naam}</h3>
                        <div className="flex-none text-xs text-gray-500">
                            {aanmelding.aanmaakDatum.toLocaleDateString()}
                        </div>
                    </div>
                    <p className="mt-3 truncate text-sm text-gray-500">
                        Aangemeld op <span className="text-gray-700">{aanmelding.aanmaakDatum.toLocaleDateString()}</span>
                        Door <span className="font-mono text-gray-700"></span>
                        in <span className="text-gray-700">{aanmelding.gemeente}</span>
                    </p>
                </li>
            ))}
        </ul>
    )
}
