import { DocumentReference, getDoc } from 'firebase/firestore';
import { getBedrijfDocumentByReference } from '../datasource/bedrijf-utils';

export type BedrijfModel = {
    reference: DocumentReference,
    locatie: string;
    data_eigenaar_ref: DocumentReference;
    huisnummer: string;
    logo: string;
    naam: string;
    plaats: string;
    postcode: string;
    soort: null; 
    straat: string;
}


// export const getBedrijven = async (max: number): Promise<BedrijfModel[]> => {
//     const list: BedrijfModel[] = [];
//     const bedrijvenData = await getBedrijven(max); 

//     bedrijvenData.forEach((bedrijf) => {
//         list.push({
//             data_eigenaar_ref: bedrijf.data_eigenaar_ref,
//             huisnummer: bedrijf.huisnummer,
//             logo: bedrijf.logo,
//             naam: bedrijf.naam,
//             plaats: bedrijf.plaats,
//             postcode: bedrijf.postcode,
//             soort: null,
//             straat: bedrijf.straat,
//             // locatie: `${bedrijf.plaats|| ''} ${bedrijf.straat|| ''} ${bedrijf.postcode || ''}`.trim(),
//         });
//     });

//     return list; 
// };

export const getBedrijfByReference = async (werkbedrijfRef: DocumentReference) => {
    const bedrijf = await getBedrijfDocumentByReference(werkbedrijfRef.id)

    if (!bedrijf) {

        return null;
    }
  
    return {
        reference: bedrijf.reference,
        data_eigenaar_ref: bedrijf.data_eigenaar_ref,
        huisnummer: bedrijf.huisnummer,
        logo: bedrijf.logo,
        naam: bedrijf.naam,
        plaats: bedrijf.plaats,
        postcode: bedrijf.postcode,
        soort: null,
        straat: bedrijf.straat,
        locatie: bedrijf.plaats + " " + bedrijf.straat + " " + bedrijf.postcode,
    };
};

export const getBedrijfById = async (id: string) => {
    const bedrijf = await getBedrijfDocumentByReference(id)

    if (!bedrijf) {

        return null;
    }
  
    return {
        reference: bedrijf.reference,
        data_eigenaar_ref: bedrijf.data_eigenaar_ref,
        huisnummer: bedrijf.huisnummer,
        logo: bedrijf.logo,
        naam: bedrijf.naam,
        plaats: bedrijf.plaats,
        postcode: bedrijf.postcode,
        soort: null,
        straat: bedrijf.straat,
        locatie: bedrijf.plaats + " " + bedrijf.straat + " " + bedrijf.postcode,
    };
};

  
