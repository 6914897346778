import { collection, query, getDocs, DocumentReference, Timestamp, where } from 'firebase/firestore';
import { db } from '../utils/firebase';
import { getTalentById } from '../datasource/talent-utils';
import { getLopendeTrajecten } from './lopend-traject-utils';
import { getActieveLopendeTrajectenDocuments } from '../datasource/lopend-traject-utils';
import { getTrajectById, TrajectModel } from './traject-utils';
import dayjs from 'dayjs';

export type SignaalModel = {
    datum?: Date,
    talent: {
        id?: string;
        voornaam?: string,
        achternaam?: string,
        email?: string,
        profiel_foto?: string,
    },
    notificatieType: string
}

export const getRapportageSignalen = async () => {
    const signalen: SignaalModel[] = [];

    const lopendeTrajecten = await getActieveLopendeTrajectenDocuments();

    const currentDate = dayjs()

    const trajecten: Map<string, TrajectModel> = new Map()

    for (let lopendTraject of lopendeTrajecten) {
        if (!lopendTraject.traject_ref || !lopendTraject.begindatum) continue

        const talent = await getTalentById(lopendTraject.talent_ref);
        let traject
        if (trajecten.has(lopendTraject.traject_ref.id)) {
            traject = trajecten.get(lopendTraject.traject_ref.id)
        } else {
            traject = await getTrajectById(lopendTraject.traject_ref.id);
            trajecten.set(lopendTraject.traject_ref.id, traject!);
        }

        if (!traject)continue

        //endDate.subtract(1, 'month').toDate()


        let startDate = dayjs(lopendTraject.begindatum)
        let endDate = dayjs(startDate).add(traject.duur, 'month')
        let differenceStartCurrentDate = dayjs().diff(startDate, 'day')
        let differenceStartEndDate = endDate.diff(startDate, 'day')

        let praktijkVerklaring = traject.praktijkverklaring
        let loonwaardeMeting = traject.loonwaardemeting
        let talentPortfolio = traject.talentportfolio

        let aantalRapportages = traject.aantal_rapportages
        let intervalRapportages = (aantalRapportages <= 0) ? 0 : differenceStartEndDate / (aantalRapportages + 1)
        let aantalEvaluatieMomenten = traject.evaluatie_momenten
        let intervalEvaluaties = (aantalEvaluatieMomenten <= 0) ? 0 : differenceStartEndDate / (aantalEvaluatieMomenten + 1)
        let aantalJobcoachInterventies = traject.jobcoach_interventies
        let intervalJobcoachInterventies = (aantalJobcoachInterventies <= 0) ? 0 : differenceStartEndDate / (aantalJobcoachInterventies + 1)

        if (endDate.subtract(1, 'month').toDate() >= currentDate.toDate()) {
            if(praktijkVerklaring){
            signalen.push({
                notificatieType: "Praktijkverklaring",
                talent: {
                    achternaam: talent?.achternaam,
                    email: talent?.email,
                    id: talent?.ref.id,
                    voornaam: talent?.voornaam,
                    profiel_foto: talent?.profiel_foto
                },
                datum: endDate.subtract(1, 'month').toDate()
            })}
            if (loonwaardeMeting){
            signalen.push({
                notificatieType: "Loonwaardemeting",
                talent: {
                    achternaam: talent?.achternaam,
                    email: talent?.email,
                    id: talent?.ref.id,
                    voornaam: talent?.voornaam,
                    profiel_foto: talent?.profiel_foto
                },
                datum: endDate.subtract(1, 'month').toDate()
            })}
            if (talentPortfolio){
            signalen.push({
                notificatieType: "Talentportfolio",
                talent: {
                    achternaam: talent?.achternaam,
                    email: talent?.email,
                    id: talent?.ref.id,
                    voornaam: talent?.voornaam,
                    profiel_foto: talent?.profiel_foto
                },
                datum: endDate.subtract(1, 'month').toDate()
            })
        }
        }

        for (let i = 1; i <= aantalRapportages; i++) {
            if ((intervalRapportages * i) >= differenceStartCurrentDate) {
                signalen.push({
                    notificatieType: `${i}e Rapportage`,
                    talent: {
                        achternaam: talent?.achternaam,
                        email: talent?.email,
                        id: talent?.ref.id,
                        voornaam: talent?.voornaam,
                        profiel_foto: talent?.profiel_foto
                    },
                    datum: startDate.add(intervalRapportages * i, 'day').toDate()
                })
                break
            }
          }
      
          for (let i = 1; i <= aantalEvaluatieMomenten; i++) {
            if ((intervalEvaluaties * i) >= differenceStartCurrentDate) {
                signalen.push({
                    notificatieType: `${i}e Evaluatie`,
                    talent: {
                        achternaam: talent?.achternaam,
                        email: talent?.email,
                        id: talent?.ref.id,
                        voornaam: talent?.voornaam,
                        profiel_foto: talent?.profiel_foto
                    },
                    datum: startDate.add(intervalEvaluaties * i, 'day').toDate()
                })
                break
            }
          }

          for (let i = 1; i <= aantalJobcoachInterventies; i++) {
            if ((intervalJobcoachInterventies * i) >= differenceStartCurrentDate) {
                signalen.push({
                    notificatieType: "Jobcoach",
                    talent: {
                        achternaam: talent?.achternaam,
                        email: talent?.email,
                        id: talent?.ref.id,
                        voornaam: talent?.voornaam,
                        profiel_foto: talent?.profiel_foto
                    },
                    datum: startDate.add(intervalJobcoachInterventies * i, 'day').toDate()
                })
                break
            }
          }

          signalen.sort((a,b) => dayjs(a.datum).isBefore(dayjs(b.datum)) ? -1 : 1)

        // startDate.add(intervalRapportages * i, 'day').toDate()
        
    }
    // const lopendeTrajecten = await getLopendeTrajecten(25);

    // for (let lopendTraject of lopendeTrajecten) {
    //     const talent = await getTalentById(lopendTraject.talent_ref!);

    //     if (talent) {
    //         let datum = '';
    //         let tijd = '';

    //         if (lopendTraject.begindatum instanceof Date) {
    //             const begindatum = lopendTraject.begindatum;
    //             datum = begindatum.toLocaleDateString('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' });
    //             tijd = begindatum.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' });
    //         }

    //         signalen.push({
    //             datum: datum,
    //             tijd: tijd,
    //             talent: {
    //                 voornaam: talent.voornaam,
    //                 achternaam: talent.achternaam,
    //                 email: talent.email,
    //                 profiel_foto: talent.profiel_foto,
    //                 id: talent.ref.id
    //             },
    //             notificatieType: 'Rapportage',
    //         });
    //     }
    // }

    return signalen;
}

// const getVerjaardagSignalen = async () => {
//     const signalen: SignaalModel[] = [];
//     const currentDate = new Date();

//     const birthdayQuery = query(collection(db, 'talents'), where('birthDate', '>', currentDate));
//     const birthdaySnapshot = await getDocs(birthdayQuery);

//     birthdaySnapshot.forEach(doc => {
//         const talent = doc.data();
//         signalen.push({
//             datum: talent.birthDate.toDate().toLocaleDateString('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' }),
//             tijd: '',
//             talent: {
//                 voornaam: talent.voornaam,
//                 achternaam: talent.achternaam,
//                 email: talent.email,
//                 profiel_foto: talent.profiel_foto,
//                 id: doc.id  
//             },
//             notificatieType: 'Verjaardag',
//         });
//     });

//     return signalen;
// }

// const getGevoelSignalen = async () => {
//     const signalen: SignaalModel[] = [];

//     const gevoelQuery = query(collection(db, 'user'), where('gevoel', '<=', 3));
//     const gevoelSnapshot = await getDocs(gevoelQuery);

//     gevoelSnapshot.forEach(doc => {
//         const talent = doc.data();
//         signalen.push({
//             datum: '',
//             tijd: '',
//             talent: {
//                 voornaam: talent.voornaam,
//                 achternaam: talent.achternaam,
//                 email: talent.email,
//                 profiel_foto: talent.profiel_foto,
//                 id: doc.id
//             },
//             notificatieType: 'Gevoel',
//         });
//     });

//     return signalen;
// }

// export const getSignalen = async () => {
//     const alleSignalen: SignaalModel[] = [];

//     const rapportageSignalen = await getRapportageSignalen();
//     // const verjaardagSignalen = await getVerjaardagSignalen();
//     const gevoelSignalen = await getGevoelSignalen();

//     alleSignalen.push(...rapportageSignalen, ...gevoelSignalen);

//     // ...verjaardagSignalen,

//     alleSignalen.sort((a, b) => {
//         const dateA = a.datum ? parseDate(a.datum) : Number.MIN_SAFE_INTEGER;
//         const dateB = b.datum ? parseDate(b.datum) : Number.MIN_SAFE_INTEGER;
//         return dateA - dateB;
//     });

//     function parseDate(datum: string): number {
//         const months: { [key: string]: number } = {
//             januari: 0,
//             februari: 1,
//             maart: 2,
//             april: 3,
//             mei: 4,
//             juni: 5,
//             juli: 6,
//             augustus: 7,
//             september: 8,
//             oktober: 9,
//             november: 10,
//             december: 11
//         };
//         const [day, monthName, year] = datum.split(' ');
//         const month = months[monthName.toLowerCase()];
//         const parsedDate = new Date(parseInt(year), month, parseInt(day));
//         return parsedDate.getTime();
//     }

//     return alleSignalen;
// }
