import { useEffect, useRef, useState } from "react"

const Stepper = ({steps, currentStep}) => {

    const [newStep, setNewStep] = useState<{completed: boolean, highlighted: boolean, selected: boolean}[]>([]);
    const stepRef = useRef(null);
    const updateStep = (stepNumber, steps) => {
        const newSteps = [...steps]
        let count = 0

        while (count<newSteps.length) {
            if (count == stepNumber) {
                newSteps[count] = {...newSteps[count], highlighted: true, selected: true, completed: true}
                count++
            } else if (count < stepNumber) {
                newSteps[count] = {...newSteps[count], highlighted: false, selected: true, completed: true}
                count++
            } else {
                newSteps[count] = {...newSteps[count], highlighted: false, selected: false, completed: false}
                count++
            }
            
        }

        return newSteps
    }

    useEffect(() => {
        const stepsState = steps.map((step, index) => {
            Object.assign({}, {
                completed: false,
                highlighted: index === 0 ? true : false,
                selected: index === 0 ? true: false
            })
        });

        stepRef.current = stepsState;
        const current = updateStep(currentStep - 1, stepRef.current);
        setNewStep(current);
    }, [steps, currentStep])

    const displaySteps = newStep.map((step, index) =>  {
        return (<li key={index} className="md:flex-1">
            {step.completed ? (
              <a
                className="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm font-medium text-indigo-600 group-hover:text-indigo-800"></span>
                <span className="text-sm font-medium">{steps[index]}</span>
              </a>
            ) : step.selected ? (
              <a
                aria-current="step"
                className="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm font-medium text-indigo-600"></span>
                <span className="text-sm font-medium">{steps[index]}</span>
              </a>
            ) : (
              <a
                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700"></span>
                <span className="text-sm font-medium">{steps[index]}</span>
              </a>
            )}
          </li>)
    })

    return (
        <div className='pb-3.5 border-b border-gray-200'>
        <nav aria-label="Progress">
          <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
            {displaySteps}
          </ol>
        </nav>
      </div>
    )
}

export default Stepper