import { useEffect, useState } from "react";

export default function DefaultImage({ profiel_foto, className }) {
  const [imageSrc, setImageSrc] = useState(profiel_foto || "https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/hOclsK0v6r1MxJZCAkio/pub/5SrfjlJfSmCcc7l8fljJ.png");

  const handleImageError = () => {
    setImageSrc("https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/hOclsK0v6r1MxJZCAkio/pub/5SrfjlJfSmCcc7l8fljJ.png");
  };


  useEffect(() => {
    setImageSrc(
      profiel_foto || "https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/hOclsK0v6r1MxJZCAkio/pub/5SrfjlJfSmCcc7l8fljJ.png"
    );
  }, [profiel_foto]);


  return (
    <div>
      <img
        alt="Profile"
        className={className}
        src={imageSrc}
        onError={handleImageError}
      />
    </div>
  );

  //Function "IF" User/Talent geen profiel foto heeft dan set "DefaultImage".
  //Else if User/Talent wel een profiel foto heeft, maar deze laad niet dan set "DefaultImage".
  //Else User/Talent wel een profiel foto heeft en deze laad gewoon dan laat die foto zien.
}

