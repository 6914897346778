import { createContext, useState } from "react";
import { NewIntakeModel } from "../domain/intake-utils";

export type IntakeModel = {
    data: NewIntakeModel,
    setData: (model: NewIntakeModel) => void
}

export const StepperContext = createContext<IntakeModel>({
    data: {},
    setData: () => {}
})