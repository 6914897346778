import { Navigate, redirect } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import Navbar from "./Navbar";
import { Analytics } from "@vercel/analytics/react"


const Layout = ({ children }) => {
  return (
    <>
      <div className="overflow-hidden">
        <Analytics />
        <Navbar />
        <main className="mx-auto px-4 sm:px-6 h-[calc(100vh-65px) max-w-[2160px]">{children}</main>
        {/* <main className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl h-[calc(100vh-65px)]">{children}</main> */}
      </div>
    </>
  )
}

export default Layout