import { DocumentReference } from "firebase/firestore"
import { getLopendeTrajectenByWerkvloerbegeleider, getTotalCountOfTalentsByWerkvloerbegeleider } from "../datasource/lopend-traject-utils"
import { getTalentById } from "../datasource/talent-utils"
import { TalentModel } from "./talent-utils"
import getAllWerkvloerbegeleider, { getWerkvloerbegeleiderById as _getWerkvloerbegeleiderById } from "../datasource/werkvloerbegeleider-utils"

export type WerkvloerbegeleiderModel = {
    reference: DocumentReference,
    achternaam: string,
    data_eigenaar_ref: DocumentReference,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    telefoon_nummer: string,
    voornaam: string,
    talenten?: TalentModel[]
}

const getWerkvloerbegeleiders = async () => {
    const werkvloerbegeleiders = await getAllWerkvloerbegeleider(50)

    const list: WerkvloerbegeleiderModel[] = []

    werkvloerbegeleiders.forEach((werkvloerbegeleider) => {
        list.push({
            reference: werkvloerbegeleider.reference,
            achternaam : werkvloerbegeleider.achternaam,
            data_eigenaar_ref : werkvloerbegeleider.data_eigenaar_ref,
            email :werkvloerbegeleider.email,
            gebruikers_rol : werkvloerbegeleider.gebruikers_rol,
            geslacht : werkvloerbegeleider.geslacht,
            profiel_foto : werkvloerbegeleider.profiel_foto,
            telefoon_nummer : werkvloerbegeleider.telefoon_nummer,
            voornaam : werkvloerbegeleider.voornaam, 
        })
    })

    return list
    
}

export default getWerkvloerbegeleiders

const getWerkvloerbegeleiderById = async (reference: DocumentReference) => {
    const werkvloerbegeleider = await _getWerkvloerbegeleiderById(reference)

    if (!werkvloerbegeleider) {
        return null
    }

    return {
        reference: werkvloerbegeleider.reference,
        achternaam : werkvloerbegeleider.achternaam,
        data_eigenaar_ref : werkvloerbegeleider.data_eigenaar_ref,
        email :werkvloerbegeleider.email,
        gebruikers_rol : werkvloerbegeleider.gebruikers_rol,
        geslacht : werkvloerbegeleider.geslacht,
        profiel_foto : werkvloerbegeleider.profiel_foto,
        telefoon_nummer : werkvloerbegeleider.telefoon_nummer,
        voornaam : werkvloerbegeleider.voornaam, 
    } as WerkvloerbegeleiderModel
}

export const getTalentsByWerkvloerbegeleider = async (id: DocumentReference | string) => {

    const werkvloerbegeleider = await _getWerkvloerbegeleiderById(id)

    if (!werkvloerbegeleider) {
        return null
    }

    const lopendeTrajecten = await getLopendeTrajectenByWerkvloerbegeleider(werkvloerbegeleider.reference)

    const model: WerkvloerbegeleiderModel = {
        reference: werkvloerbegeleider.reference,
        achternaam : werkvloerbegeleider.achternaam,
        data_eigenaar_ref : werkvloerbegeleider.data_eigenaar_ref,
        email :werkvloerbegeleider.email,
        gebruikers_rol : werkvloerbegeleider.gebruikers_rol,
        geslacht : werkvloerbegeleider.geslacht,
        profiel_foto : werkvloerbegeleider.profiel_foto,
        telefoon_nummer : werkvloerbegeleider.telefoon_nummer,
        voornaam : werkvloerbegeleider.voornaam, 
    }

    let talenten: TalentModel[] = []

    for (const lopendTraject of lopendeTrajecten) {
        if (lopendTraject.talent_ref) {
            let talent = await getTalentById(lopendTraject.talent_ref)

            if (talent)
                talenten.push({
                    id: talent.ref.id,
                    ref: talent.ref,
                    gemeente: talent.talent?.gemeente,
                    huisnummer: talent.talent?.huisnummer,
                    nationaliteit: talent.talent?.nationaliteit,
                    plaats: talent.talent?.plaats,
                    postcode: talent.talent?.postcode,
                    straat: talent.talent?.straat,
                    telefoonnummer: talent.telefoon_nummer,
                    voornaam: talent.voornaam,
                    achternaam: talent.achternaam,
                    volledige_naam: talent.voornaam + " " + talent.achternaam,
                    foto: talent.profiel_foto,
                    email: talent.email,
                    rol: talent.gebruikers_rol,
              
                  })
        }
    }

    model.talenten = talenten

    return model

}

export const getTotalTalentsByWerkvloerbegeleiders= async (werkvloerbegeleiders: WerkvloerbegeleiderModel[]) => {
    const total = await getTotalCountOfTalentsByWerkvloerbegeleider(werkvloerbegeleiders.map((werkvloerbegeleider) => {return werkvloerbegeleider.reference}))
    console.log(total)
    return total
}