import Layout from "../layout"
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { TalentModel, getTalents } from "../domain/talent-utils"
import { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import Chart from "../components/charts/chart"
import { EvaluatieModel, getAllEvaluaties, getEvaluatiesByTalentId } from './../domain/evaluatie-utils'
import { getRapportageSignalen, SignaalModel } from "../domain/signalen-utils"
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import Grafiek from "../components/charts/barchart/Grafiek";
import DefaultImage from "../components/defaultimage/defaultimage";
import { getGenerativeModel } from "firebase/vertexai-preview";
import { vertexAI } from "../utils/firebase";
import Aanmeldingen from "../components/lists/aanmeldingen/Aanmeldingen";
import Griddocuments from "../components/grid/documents/Griddocuments";
import { AanmeldingModel, getAanmeldingen } from "../domain/aanmelding-utils";
import TrajectTab from "../components/traject/App";
import { useAuth } from "../context/AuthContext";
import { fetchRapportages, getRapportages, RapportageModel } from "../domain/rapportage-utils";

function Change() {

}

// import { useParams } from 'react-router-dom'

const data = [
  { name: 'Page A', uv: 400, pv: 2400, amt: 2400 },
  { name: 'Page B', uv: 300, pv: 4567, amt: 2400 },
  { name: 'Page C', uv: 300, pv: 1398, amt: 2400 },
  { name: 'Page D', uv: 200, pv: 9800, amt: 2400 },
  { name: 'Page A', uv: 400, pv: 2400, amt: 2400 },
  { name: 'Page B', uv: 300, pv: 4567, amt: 2400 },
  { name: 'Page C', uv: 300, pv: 1398, amt: 2400 },
  { name: 'Page D', uv: 200, pv: 9800, amt: 2400 }
];


function Home() {
  const [aanmeldingen, setAanmeldingen] = useState<AanmeldingModel[]>([])
  const [signalen, setSignalen] = useState<SignaalModel[]>([])
  const [evaluaties, setEvaluaties] = useState<EvaluatieModel[]>([])
  const [rapportages, setRapportages] = useState<RapportageModel[]>([])
  useEffect(() => {
    Promise.all([getAanmeldingen(), getRapportageSignalen(), getAllEvaluaties(), fetchRapportages(25, {talent: true})]).then(([aanmeldingen, signalen, evaluaties, rapportages]) => {
      setAanmeldingen(aanmeldingen)
      setSignalen(signalen)
      setEvaluaties(evaluaties)
      setRapportages(rapportages)
    })
  }, [])

  const {user} = useAuth()

  return (
    <Layout>
      <div className="overflow-auto">
        <h1 className="sr-only">Page title</h1>

        {/* First section */}
        <div className="grid grid-cols-1 rounded-lg items-stretch gap-3 lg:grid-cols-3 bg-slate-50 h-[calc(100vh-50vh)] pt-6 pl-4 overflow-hidden ">

          {/* Left column */}
          <div className="grid grid-rows-2 gap-4 lg:col-span-2 pl-6">
            <div className="">
              <h3 className="text-4xl font-regular text-gray-900 mt-6">Welkom👋🏻 {user?.voornaam} {user?.achternaam}, <br/>dit is jouw score:</h3>
            </div>

            <div className="-mt-4">
              <h3 className="text-9xl font-thin text-gray-900">BI 70%</h3>
              <p className="mt-1 text-sm text-gray-500">Laatste updat: 10 minuten geleden</p>
            </div>

            <div className="-mt-28">
              <Grafiek />
            </div>
          </div>

          {/* Right column */}
          <div className="grid grid-cols-1 gap-4 overflow-y-scroll backdrop-blur-lg">
            <section aria-labelledby="section-2-title">
              <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap pr-4">
                <div className="ml-4 mt-4">
                  <h3 className="text-base font-semibold text-gray-900">Signalen</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Dit ligt er op mijn bordje
                  </p>
                </div>
                <div className="ml-4 mt-4 shrink-0">
                  <button
                    type="button"
                    className="relative inline-flex items-center rounded-md bg-slate-200 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-slate-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Bekijk Signalen
                  </button>
                </div>
              </div>

              <div className="overflow-hidden pt-4 ">
                <div className="">
                  <ul role="list" className="divide-y divide-gray-100">
                    {signalen.map((signaal) => (
                      <Link to={`/talentenhub/${signaal.talent.id}`} className="relative truncate hover:underline">
                        <li
                          key={signaal.talent.email}
                          className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-slate-100 sm:px-6 lg:px-8"
                        >
                          <div className="flex  gap-x-4">
                            <DefaultImage profiel_foto={signaal.talent.profiel_foto} className="inline-block h-12 w-12 rounded-full" />
                            {/* <img alt="" src={signaal.talent.profiel_foto} className="h-12 w-12 flex-none rounded-full bg-gray-50" /> */}
                            <div className="min-w-0 flex-auto"
                            >
                              <p className="text-sm font-semibold leading-6 text-gray-900">
                                <a href={"#"}>
                                  <span className="absolute inset-x-0 -top-px bottom-0" />
                                  {signaal.talent.voornaam} {signaal.talent.achternaam}
                                </a>
                              </p>
                              <p className="mt-1  text-xs leading-5 text-gray-500">
                                <a href={`mailto:${signaal.talent.email}`} className="relative truncate hover:underline"></a>
                                {/* */}

                                {signaal.notificatieType}
                              </p>
                            </div>
                          </div>
                          <div className="flex shrink-0 items-center gap-x-4">
                            <div className="hidden sm:flex sm:flex-col sm:items-end">

                              <p className="text-xs leading-6 text-gray-900">
                                {signaal.datum?.toLocaleDateString()}
                              </p>
                            </div>
                            <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                          </div>
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </div>



        {/* Second section */}
        <div className="grid grid-cols-1 max-h-screen rounded-lg items-stretch gap-4 lg:grid-cols-3  pt-12 pl-4 lg:overflow-hidden">


          {/* Left column */}
          <div className="grid grid-cols-1 gap-4 lg:col-span-1 grid-rows-2 overflow-y-scroll">
            <section aria-labelledby="section-1-title ">
            <h3 className="text-base font-semibold text-gray-900">Laatste rapportages</h3>
              <p className="mt-1 text-sm text-gray-500">Hoe goed gaat het nou echt</p>

              <div className="overflow-hidden pt-4 ">
                <Griddocuments rapportages={rapportages} />
              </div>
            </section>
          </div>


          {/* Middle column */}
          <div className="grid grid-cols-1 gap-4 lg:col-span-1 grid-rows-2 ">
            <section aria-labelledby="section-1-title ">
              <h3 className="text-base font-semibold text-gray-900">Nieuwe aanmeldingen</h3>
              <p className="mt-1 text-sm text-gray-500">Deze Toppers staan te popelen</p>

              <div className="overflow-hidden pt-4">
                <Aanmeldingen aanmeldingen={aanmeldingen} />
              </div>
            </section>
          </div>


          {/* Right column */}
          <div className="grid grid-cols-1 gap-4 h-max overflow-y-auto">
            {TrajectTab(evaluaties)}
          </div>
        </div>


      </div>
    </Layout>
  )
}

export default Home