"use client";

import { createContext, useContext, useEffect, useState } from 'react';
import { onIdTokenChanged, signInWithEmailAndPassword, signOut, UserCredential, User } from 'firebase/auth';
import { auth } from '../utils/firebase';
import { _getUserById, UserModel } from '../domain/user-utils';

interface AuthContext {
  user: UserModel | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  logOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContext>({
  user: null,
  loading: false,
  signIn: async (email: string, password: string) => signInWithEmailAndPassword(auth, email, password),
  logOut: async () => {}
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<UserModel | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return onIdTokenChanged(auth, async (_user) => {
      
      if (!_user) {
        setUser(null)
        setLoading(false)
      } else {
        _getUserById(_user.uid).then((response) => {
          setUser(response)
          setLoading(false)
        })
      }

      

    });
  }, []);

  // // force refresh the token every 10 minutes
  // useEffect(() => {
  //   const handle = setInterval(async () => {
  //     const user = auth.currentUser;
  //     if (user) await user.getIdToken(true);
  //   }, 10 * 60 * 1000);

  //   // clean up setInterval
  //   return () => clearInterval(handle);
  // }, []);

  // const signUp = (email: string, password: string) => {
  //   return createUserWithEmailAndPassword(auth, email, password)
  // }

  const signIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
  };

  const logOut = () => {
    setUser(null)
    return signOut(auth);
  };

  return (
    <AuthContext.Provider value={{user, loading, signIn, logOut}}>{children}</AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext);
}