import { PlusSmallIcon } from '@heroicons/react/20/solid'
import { Link, useLocation } from 'react-router-dom'

export default function Example() {
  const locatie = useLocation();

  return (
    <div>
      <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8 py- border-b border-gray-200 pb-5 ">
        <h1 className="text-base font-semibold leading-7 text-gray-900">Talentenhub</h1>
        <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7 ">
          <a href="/talentenhub" className={locatie.pathname === '/talentenhub' ? 'text-sky-400' : 'text-gray-700 hover:text-sky-400'}>
            Talenten
          </a>
          <a href="/talentenhub/aanmeldingen" className={locatie.pathname === '/talentenhub/aanmeldingen' ? 'text-sky-400' : 'text-gray-700 hover:text-sky-400'}>
            Aanmeldingen
          </a>
          <a href="/talentenhub/intakes" className={locatie.pathname === '/talentenhub/intakes' ? 'text-sky-400' : 'text-gray-700 hover:text-sky-400'}>
            Intakes
          </a>
          <a href="/talentenhub/rapportages" className={locatie.pathname === '/talentenhub/rapportages' ? 'text-sky-400' : 'text-gray-700 hover:text-sky-400'}>
            Rapportages
          </a>
          <a href="/talentenhub/caseload" className={locatie.pathname === '/talentenhub/caseload' ? 'text-sky-400' : 'text-gray-700 hover:text-sky-400'}>
            Caseload
          </a>
        </div>

      </div>
    </div>
  )
}
