import { addDoc, collection, doc, DocumentReference, getCountFromServer, getDoc, getDocs, query, QueryDocumentSnapshot, SnapshotOptions, where } from "firebase/firestore";
import { db } from "../utils/firebase";

export type RemoteAanmelding = {
    reference: DocumentReference,
    data_eigenaar_ref: DocumentReference,
    voornaam: string,
    achternaam: string,
    geboortedatum: Date,
    geslacht: string,
    email: string,
    telefoon: string,
    nationaliteit: string,
    afkomst: string,
    straat: string,
    huisnummer: string,
    postcode: string,
    woonplaats: string,
    traject_reference: DocumentReference,
    gemeente: string,
    consulent_reference: DocumentReference,
    situatie_schets: string,
    doelstellingen: string,
    bijzonderheden: string,
    aanmaak_datum: Date
};

export type NewRemoteAanmelding = {
    data_eigenaar_ref?: DocumentReference,
    voornaam: string,
    achternaam: string,
    geboortedatum: Date,
    geslacht: string,
    email: string,
    telefoon: string,
    nationaliteit: string,
    afkomst: string,
    straat: string,
    huisnummer: string,
    postcode: string,
    woonplaats: string,
    traject_reference: DocumentReference,
    gemeente: string,
    consulent_reference: DocumentReference,
    situatie_schets: string,
    doelstellingen: string,
    bijzonderheden: string,
    aanmaak_datum: Date
};

const aanmeldingConverter = {
    toFirestore: (aanmelding: RemoteAanmelding) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteAanmelding {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            data_eigenaar_ref: data.data_eigenaar_ref,
            voornaam: data.voornaam,
            achternaam: data.achternaam,
            geboortedatum: data.geboortedatum.toDate(),
            geslacht: data.geslacht,
            email: data.email,
            telefoon: data.telefoon,
            nationaliteit: data.nationaliteit,
            afkomst: data.afkomst,
            straat: data.straat,
            huisnummer: data.huisnummer,
            postcode: data.postcode,
            woonplaats: data.woonplaats,
            traject_reference: data.traject_reference,
            gemeente: data.gemeente,
            consulent_reference: data.consulent_reference,
            situatie_schets: data.situatie_schets,
            doelstellingen: data.doelstellingen,
            bijzonderheden: data.bijzonderheden,
            aanmaak_datum: data.aanmaak_datum.toDate()
        }
    }
}

export const getAanmeldingDocuments = async () => {
    let q = query(collection(db, "aanmelding")).withConverter(aanmeldingConverter)

    const querySnapshot = await getDocs(q);
    const list: RemoteAanmelding[] = []
    querySnapshot.forEach((doc) => {
        return list.push(doc.data());
    });

    return list;
}

export const getAanmeldingDocumentById = async (id: string) => {
    const reference = await doc(db, "aanmelding", id).withConverter(aanmeldingConverter)
    const docSnap = await getDoc(reference);

    if (!docSnap.exists())
        return null

    return docSnap.data()
}

export const createAanmeldingDocument = (data: NewRemoteAanmelding) => {
    return addDoc(collection(db, "aanmelding"), data)
}

export const getCountAanmeldingenByTraject = async (reference: DocumentReference) => {
    const q = query(collection(db, "aanmelding"), where("traject_reference", "==", reference));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count
}