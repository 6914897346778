import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import Layout from '../../../layout'
import Talenten from '../../../components/subnav/talenten/talenten'
import { getIntakes } from '../../../domain/intake-utils'
import { useEffect, useState } from 'react'
import { AanmeldingModel, getAanmeldingen } from '../../../domain/aanmelding-utils'

const statuses = {
    Nieuw: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}
const projects = [
    {
        id: 1,
        name: 'Selcheley Michiel',
        href: '/talentenhub/intakeformulier',
        status: 'Nieuw',
        createdBy: 'Leslie Alexander',
        dueDate: 'March 17, 2023',
        dueDateTime: '2023-03-17T00:00Z',
    },
    {
        id: 2,
        name: 'New benefits plan',
        href: '#',
        status: 'Nieuw',
        createdBy: 'Leslie Alexander',
        dueDate: 'May 5, 2023',
        dueDateTime: '2023-05-05T00:00Z',
    },
    {
        id: 3,
        name: 'Onboarding emails',
        href: '#',
        status: 'In progress',
        createdBy: 'Courtney Henry',
        dueDate: 'May 25, 2023',
        dueDateTime: '2023-05-25T00:00Z',
    },
    {
        id: 4,
        name: 'iOS app',
        href: '#',
        status: 'In progress',
        createdBy: 'Leonard Krasner',
        dueDate: 'June 7, 2023',
        dueDateTime: '2023-06-07T00:00Z',
    },
    {
        id: 5,
        name: 'Marketing site redesign',
        href: '#',
        status: 'Archived',
        createdBy: 'Courtney Henry',
        dueDate: 'June 10, 2023',
        dueDateTime: '2023-06-10T00:00Z',
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Aanmeldingen() {

    const [aanmeldingen, setAameldingen] = useState<AanmeldingModel[]>([])

    useEffect(() => {

        getAanmeldingen().then((response) => {
            setAameldingen(response)
        })

    }, [])

    return (
        <Layout>

<Talenten/>
            <ul role="list" className="mx-auto max-w-7xl divide-y divide-gray-100 ">
                {aanmeldingen.map((aanmelding) => (
                    <li key={aanmelding.id} className="flex items-center justify-between gap-x-6 py-5">
                        <div className="min-w-0">
                            <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{aanmelding.volledige_naam}</p>
                            </div>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <p className="whitespace-nowrap">
                                    Binnengekomen op {aanmelding.aanmaakDatum.toLocaleDateString()}
                                </p>
                                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                    <circle r={1} cx={1} cy={1} />
                                </svg>
                                <p className="truncate">Consulent: {aanmelding.consulent.volledige_naam}</p>
                            </div>
                        </div>
                        <div className="flex flex-none items-center gap-x-4">
                            <a
                                href='/talentenhub/aanmeldingdocument'
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                            >
                                Bekijk aanmelding
                            </a>
                            <a
                                href='/talentenhub/aanmeldingdocument'
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                            >
                                Talent uitnodigen
                            </a>
                            <a
                                href={`/talentenhub/intakeformulier/${aanmelding.id}`}
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                            >
                                Intake starten
                            </a>
                            <a
                                href='/talentenhub/aanmeldingdocument'
                                className="hidden rounded-md bg-red px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                            >
                                Afmelden
                            </a>
                            <Menu as="div" className="relative flex-none">
                                <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                    <span className="sr-only">Open options</span>
                                    <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
                                </MenuButton>
                                <MenuItems
                                    transition
                                    className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    {/* <MenuItem>
                                        <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                                            Edit<span className="sr-only">, {project.name}</span>
                                        </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                                            Move<span className="sr-only">, {project.name}</span>
                                        </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                                            Delete<span className="sr-only">, {project.name}</span>
                                        </a>
                                    </MenuItem> */}
                                </MenuItems>
                            </Menu>
                        </div>
                    </li>
                ))}
            </ul>
        </Layout>
    )
}