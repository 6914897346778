import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import Bloeiindex from '../../images/Bloeiindex_Purple@4.png'
import BackgroundImage from '../../images/the-hands-of-two-people-help-each-other-are-planti-2022-09-14-06-39-07_LR-utc.jpg'
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useAuth } from "../../context/AuthContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { FirebaseError } from "firebase/app";
import { multiFactor, MultiFactorError, TotpSecret } from "firebase/auth";
import { displayMutliFactor } from "../../utils/auth";
import MFAAuthRegister from "../../components/modal/auth-register/Auth-register";
import MFAAuthValidate from "../../components/modal/auth-validate/Auth-validate";

export default function Login() {
  const [mfa, setMFA] = useState<MultiFactorError>()
  const [code, setCode] = useState("")
  const [secretKey, setSecretKey] = useState<TotpSecret>()

  // const [open, setOpen] = useState(false)

  const {signIn, logOut} = useAuth()

  type Inputs = {
    email: string,
    password: string
  }

const {
    register,
    handleSubmit,
    formState: {errors},
} = useForm<Inputs>()
const onSubmit: SubmitHandler<Inputs> = (data) => {
  signIn(data.email, data.password).then(async (user) => {
    if (!user.user.emailVerified) {
      throw new FirebaseError("auth/unverified-email", "Email must be verified to perform mfa.")
    }
    displayMutliFactor().then((response) => {
      setSecretKey(response!.secret_key)
      setCode(response!.qr_display)
    })
  }).catch((e) => {
    switch (e.code) {
      case 'auth/unverified-email':
        // sendEmailVerification(auth.currentUser!)
        logOut()
        // setOpen(true)
        break;
      case 'auth/multi-factor-auth-required':
        setMFA(e)
        break;
      default:
        break
    }
  })
}

useEffect(() => {

}, [code])

  // const handleClick = async (e) => {
  //   e.preventDefault();
  //   // signInWithEmailAndPassword(auth, usernameRef.current!.value, passwordRef.current!.value)
  //   //   .then(async (user) => {
  //   //     if (!user.user.emailVerified)
  //   //       throw new FirebaseError("auth/unverified-email", "Email must be verified to perform mfa.")


  //   //   })
  //   //   .catch((e) => {
  //   //     switch (e.code) {
  //   //       case 'auth/unverified-email':
  //   //         sendEmailVerification(auth.currentUser!)
  //   //         signOut(auth)
  //   //         setOpen(true)
  //   //         break;
  //   //       case 'auth/multi-factor-auth-required':
  //   //         break;
  //   //       default:
  //   //         break
  //   //     }
  //   //   })
  //   signIn(usernameRef.current!.value, passwordRef.current!.value).then((response) => {
  //     if (response.user) {
  //       window.location.href = "/"
  //       // console.log(response.user)
  //     }
  //   })
  // }

  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-white">
          <body class="h-full">
          ```
        */}
      {/* <Dialog open={open} onClose={setOpen}>
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">Update your email</h3>
                  <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>Change the email address you want associated with your account.</p>
                  </div>
                  <form className="mt-5 sm:flex sm:items-center">
                    <div className="w-full sm:max-w-xs">
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          required
                          autoComplete="email"
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
  
                    <div>
                      <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                        Wachtwoord
                      </label>
                      <div className="mt-2">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          required
                          autoComplete="current-password"
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
  
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                          Onthoud mijn gegevens
                        </label>
                      </div>
  
                      
                    </div>
  
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-violet-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Aan de slag!
                      </button>
                      <div className="flex w-full justify-center text-sm leading-6 pt-4">
                        <a href="#" className="font-semibold text-sky-400 hover:text-sky-600">
                          Nog geen account? Klik hier!
                        </a>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto"
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </DialogPanel>
          </div></div>
      </Dialog> */}
      <div className="flex flex-1 h-screen">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
          <div className="mx-auto w-full max-w-sm lg:w-96 pb-24">
            <div>
              <img
                alt="Bloeiindex"
                src={Bloeiindex}
                className="h-8 w-auto"
              />
              <h2 className="mt-24 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Inloggen
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Nog geen Bloeiindex?{' '}
                <a href="https://www.bloeiindex.nl" className="font-semibold text-sky-400 hover:text-sky-600">
                  Start vandaag nog!
                </a>
              </p>
            </div>

            <div className="mt-10">
              <div>
                <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Email
                    </label>
                    <div className="mt-2">
                      <input
                        {...register("email", {required: {value: true, message: "Email is verplicht"}})}
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors["email"] && <span>{errors["email"]?.message}</span>}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Wachtwoord
                    </label>
                    <div className="mt-2">
                      <input
                      type="password"
                        {...register("password", {required: {value: true, message: "Wachtwoord is verplicht"}})}
                        autoComplete="current-password"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors["password"] && <span>{errors["password"]?.message}</span>}
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                        Onthoud mijn gegevens
                      </label>
                    </div>

                    <div className="text-sm leading-6">
                      <a href="#" className="font-semibold text-sky-400 hover:text-sky-600">
                        Wachtwoord vergeten?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      className="flex w-full justify-center rounded-md bg-violet-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Aan de slag!
                    </button>
                  </div>
                </form>
              </div>

              {/* <div className="mt-10">
                  <div className="relative">
                    <div aria-hidden="true" className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                      <span className="bg-white px-6 text-gray-900">Or continue with</span>
                    </div>
                  </div>
  
                  <div className="mt-6 grid grid-cols-2 gap-4">
                    <a
                      href="#"
                      className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                    >
                      <svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
                        <path
                          d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                          fill="#EA4335"
                        />
                        <path
                          d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                          fill="#34A853"
                        />
                      </svg>
                      <span className="text-sm font-semibold leading-6">Google</span>
                    </a>
  
                    <a
                      href="#"
                      className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                    >
                      <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="h-5 w-5 fill-[#24292F]">
                        <path
                          d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                          clipRule="evenodd"
                          fillRule="evenodd"
                        />
                      </svg>
                      <span className="text-sm font-semibold leading-6">GitHub</span>
                    </a>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            alt=""
            src={BackgroundImage}
            className="absolute inset-0 h-full w-full object-cover"
          />
        </div>
      </div>
      {code && <MFAAuthRegister code={code} totpKey={secretKey}/>}
      {mfa && <MFAAuthValidate error={mfa}/>}
    </>
  )
}
