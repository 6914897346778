import { DocumentReference, writeBatch } from 'firebase/firestore';
import _getIntakes, { createIntake, getIntakeDocumentById } from '../datasource/intake-utils';
import { TalentModel, getTalentsById } from './talent-utils';
import { _getUserById, getUserById } from './user-utils'; 
import { db } from '../utils/firebase';
import { getTrajectByID, getTrajectDocumentById } from '../datasource/traject-utils';
import { uploadFileToFirebase } from '../utils/files-handler';
import { getDownloadURL } from 'firebase/storage';
import { getTrajectById } from './traject-utils';


export type IntakeModel = {
    id?: string,
    datumUitgevoerd?: Date,
    gemeente?: string,
    trajectId?: string,
    traject?: {
        naam?: string
    }
    startDatum?: Date,
    consulentId?: string,
    consulent?: {
        naam?: string
    }
    eersteIndrukTalent?: string,
    documenten?: string[],
    voornaam?: string,
    achternaam?: string,
    geboortedatum?: Date,
    gender?: string,
    email?: string,
    telefoon?: string,
    nationaliteit?: string,
    afkomst?: string,
    straat?: string,
    huisnummer?: string,
    postcode?: string,
    woonplaats?: string,
    situatieSchets?: string,
    werkervaring?: string,
    soortUitkering?: string,
    vervoer?: string,
    taalniveau?: string,
    gezondheid?: string,
    opleiding?: string,
    medicijnen?: string,
    ingeburgerd?: boolean,
    allergien?: string,
    fysiekeBelasting?: string,
    diplomas?: string,
    diplomasGewaardeerd?: boolean,
    kinderopvang?: boolean,
    rijbewijs?: boolean,
    ervaringTalent?: string,
    ambitieTalent?: string,
    kunnenTalent?: string,
    voorstel?: string
}

export type NewIntakeModel = {
    gemeente?: string,
    trajectId?: string,
    startDatum?: Date,
    consulentId?: string,
    eersteIndrukTalent?: string,
    documenten?: File[],
    voornaam?: string,
    achternaam?: string,
    geboortedatum?: Date,
    gender?: string,
    email?: string,
    telefoon?: string,
    nationaliteit?: string,
    afkomst?: string,
    straat?: string,
    huisnummer?: string,
    postcode?: string,
    woonplaats?: string,
    situatieSchets?: string,
    werkervaring?: string,
    soortUitkering?: string,
    vervoer?: string,
    taalniveau?: string,
    gezondheid?: string,
    opleiding?: string,
    medicijnen?: string,
    ingeburgerd?: boolean,
    allergien?: string,
    fysiekeBelasting?: string,
    diplomas?: string,
    diplomasGewaardeerd?: boolean,
    kinderopvang?: boolean,
    rijbewijs?: boolean,
    ervaringTalent?: string,
    ambitieTalent?: string,
    kunnenTalent?: string,
    voorstel?: string
}

// gemeente?: string,
//     trajectId?: string,
//     startDatum?: Date,
//     consulentId?: string,
//     eersteIndrukTalent?: string,
//     documenten?: string[],
//     voornaam?: string,
//     achternaam?: string,
//     geboortedatum?: Date,
//     gender?: string,
//     email?: string,
//     telefoon?: string,
//     nationaliteit?: string,
//     afkomst?: string,
//     straat?: string,
//     huisnummer?: string,
//     postcode?: string,
//     woonplaats?: string,
//     situatieSchets?: string,
//     werkervaring?: string,
//     soortUitkering?: string,
//     vervoer?: string,
//     taalniveau?: string,
//     gezondheid?: string,
//     opleiding?: string,
//     medicijnen?: string,
//     ingeburgerd?: number,
//     allergien?: string,
//     fysiekeBelasting?: string,
//     diplomas?: string,
//     diplomasGewaardeerd?: boolean,
//     kinderopvang?: boolean,
//     rijbewijs?: boolean,
//     ervaringTalent?: string,
//     ambitieTalent?: string,
//     kunnenTalent?: string,
//     voorstel?: string

export const getIntakes = async (max: number, options?: {consulent?: boolean}) => {
    const list: IntakeModel[] = [];
    const intakes = await _getIntakes();

    for (const intake of intakes) {
        const intakeData: IntakeModel = {
            id: intake.reference.id,
            datumUitgevoerd: intake.datum_uitgevoerd,
            gemeente: intake.gemeente,
            trajectId: intake.traject_reference?.id,
            startDatum: intake.start_datum,
            eersteIndrukTalent: intake.eerste_indruk_talent,
            documenten: intake.documenten,
            voornaam: intake.voornaam,
            achternaam: intake.achternaam,
            geboortedatum: intake.geboortedatum,
            gender: intake.geslacht,
            email: intake.email,
            telefoon: intake.telefoon,
            nationaliteit: intake.nationaliteit,
            afkomst: intake.afkomst,
            straat: intake.straat,
            huisnummer: intake.huisnummer,
            postcode: intake.postcode,
            woonplaats: intake.woonplaats,
            situatieSchets: intake.korte_situatieschets,
            werkervaring: intake.werkervaring,
            soortUitkering: intake.soort_uitkering,
            vervoer: intake.vervoer,
            taalniveau: intake.taalniveau,
            gezondheid: intake.gezondheid,
            opleiding: intake.opleiding,
            medicijnen: intake.medicijnen,
            ingeburgerd: intake.ingeburgerd,
            allergien: intake.allergien,
            fysiekeBelasting: intake.fysieke_belasting,
            diplomas: intake.diplomas,
            diplomasGewaardeerd: intake.diplomas_gewaardeerd,
            kinderopvang: intake.kinderopvang,
            rijbewijs: intake.rijbewijs,
            ervaringTalent: intake.talent_ervaring,
            ambitieTalent: intake.talent_wensen,
            kunnenTalent: intake.talent_kunnen,
            voorstel: intake.voorstel
        };

        if (options?.consulent) {
            let consulent = await getUserById(intake?.consulent_reference)
            intakeData.consulent = {
                naam: `${consulent?.voornaam} ${intake.achternaam}`
            } 
        } else {
            intakeData.consulentId = intake.consulent_reference?.id
        }
        
        list.push(intakeData);
    }

    return list;
};

export const getIntakeById = async (id: string, options?: {consulent?: boolean, traject?: boolean}) => {
    const intake = await getIntakeDocumentById(id)

    if (!intake) return Promise.reject("Geen intake gevonden.")

    const model: IntakeModel = {
        id: intake.reference.id,
    datumUitgevoerd: intake.datum_uitgevoerd,
    gemeente: intake.gemeente,
    startDatum: intake.start_datum,
    eersteIndrukTalent: intake.eerste_indruk_talent,
    documenten: intake.documenten,
    voornaam: intake.voornaam,
    achternaam: intake.achternaam,
    geboortedatum: intake.geboortedatum,
    gender: intake.geslacht,
    email: intake.email,
    telefoon: intake.telefoon,
    nationaliteit: intake.nationaliteit,
    afkomst: intake.afkomst,
    straat: intake.straat,
    huisnummer: intake.huisnummer,
    postcode: intake.postcode,
    woonplaats: intake.woonplaats,
    situatieSchets: intake.korte_situatieschets,
    werkervaring: intake.werkervaring,
    soortUitkering: intake.soort_uitkering,
    vervoer: intake.vervoer,
    taalniveau: intake.taalniveau,
    gezondheid: intake.gezondheid,
    opleiding: intake.opleiding,
    medicijnen: intake.medicijnen,
    ingeburgerd: intake.ingeburgerd,
    allergien: intake.allergien,
    fysiekeBelasting: intake.fysieke_belasting,
    diplomas: intake.diplomas,
    diplomasGewaardeerd: intake.diplomas_gewaardeerd,
    kinderopvang: intake.kinderopvang,
    rijbewijs: intake.rijbewijs,
    ervaringTalent: intake.talent_ervaring,
    ambitieTalent: intake.talent_wensen,
    kunnenTalent: intake.talent_kunnen,
    voorstel: intake.voorstel
    }

    if (options?.consulent) {
        let consulent = await getUserById(intake?.consulent_reference)
        model.consulent = {
            naam: `${consulent?.voornaam} ${intake.achternaam}`
        } 
    } else {
        model.consulentId = intake.consulent_reference?.id
    }

    if (options?.traject) {
        let traject = await getTrajectById(intake?.traject_reference?.id)
        model.traject = {
            naam: traject?.naam
        } 
    } else {
        model.trajectId = intake.traject_reference?.id
    }

    return model

}


export const createNewIntake = async (intakeModel: NewIntakeModel) => {

    const traject = await getTrajectDocumentById(intakeModel.trajectId)
    const consulent = await _getUserById(intakeModel.consulentId)

    if (!traject || !consulent)return

    const uploads: string[] = []

    for (let file of intakeModel.documenten!) {
        let ref = await uploadFileToFirebase(file)
        let url = await getDownloadURL(ref.ref)
        uploads.push(url)
    }

    await createIntake(intakeModel, traject.reference, consulent.ref, uploads);
};