import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid'
import { EvaluatieModel } from '../../domain/evaluatie-utils'

export default function TrajectTab(evaluaties: EvaluatieModel[]) {

  return (
    <div className="flow-root ">
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Traject updates</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Alle updates van de begeleiders</p>
      </div>
      <ul role="list" className="-mb-8 mt-6">
        {evaluaties.map((evaluatie, id) => (
          <li key={id}>
            <div className="relative pb-8 ">
              {id !== evaluaties.length - 1 ? (
                <span aria-hidden="true" className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" />
              ) : null}
              <div className="relative flex items-start space-x-3 ">
              <div className="relative">
                      <img
                        alt=""
                        src={evaluatie.auteur?.profiel_foto}
                        className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                      />

                      <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                        <ChatBubbleLeftEllipsisIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 bg-slate-50 p-4 rounded-lg">
                      <div>
                        <div className="text-sm">
                          <a href="#" className="font-medium text-gray-900">
                            {evaluatie.auteur.naam}
                          </a>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">Geschreven op {evaluatie.aanmaak_datum.toLocaleDateString()}</p>
                      </div>
                      <div className="mt-2 text-sm text-gray-700">
                        <p>{evaluatie.content}</p>
                      </div>
                    </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
