import { collection, query, getDocs, where, limit, DocumentReference, getDoc, doc, getCountFromServer, orderBy } from "firebase/firestore";
import { db } from "../utils/firebase";

export type RemoteTalent = {
    ref: DocumentReference,
    achternaam: string,
    data_eigenaar_ref: DocumentReference,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    talent?: {
        gemeente: string,
        huisnummer: string,
        intake_ref: DocumentReference,
        lopendtraject_ref: DocumentReference,
        nationaliteit: string,
        plaats: string,
        postcode: string,
        straat: string,
        traject_ref: DocumentReference,
    },
    telefoon_nummer: string,
    voornaam: string,

};

export default async (max: number) => {

    const q = query(collection(db, "user"), where("gebruikers_rol", "==", "talent"), orderBy("voornaam"), limit(max));

    const querySnapshot = await getDocs(q);
    const list: RemoteTalent[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push({
            ref: doc.ref,
            achternaam : doc.data().achternaam,
                  data_eigenaar_ref : doc.data().data_eigenaar_ref,
                  email :doc.data().email,
                  gebruikers_rol : doc.data().gebruikers_rol,
                  geslacht : doc.data().geslacht,
                  profiel_foto : doc.data().profiel_foto,
                  telefoon_nummer : doc.data().telefoon_nummer,
                  talent : doc.data().talent,
                  voornaam : doc.data().voornaam, 
        })
    });

    return list; 
}

export const getTalentById = async (id: DocumentReference | string ) => {

    if(!id ){
        return null;
    }
    const docSnap =  (typeof id === "string" ) ?await getDoc( doc(db, "user", id.toString())):await getDoc(id as DocumentReference);

    /* = await getDoc(id); */
    
    if (docSnap.exists()) {
       return {
        ref: docSnap.ref,
        achternaam : docSnap.data().achternaam,
                  data_eigenaar_ref : docSnap.data().data_eigenaar_ref,
                  email :docSnap.data().email,
                  gebruikers_rol : docSnap.data().gebruikers_rol,
                  geslacht : docSnap.data().geslacht,
                  profiel_foto : docSnap.data().profiel_foto,
                  telefoon_nummer : docSnap.data().telefoon_nummer,
                  talent : docSnap.data().talent,
                  voornaam : docSnap.data().voornaam
    } as RemoteTalent;
      } else {
        return null; 
      }
}
export const getVerjaardagen = async () => {
    const currentDate = new Date();
    const verjaardag = query(collection(db, 'user'), where('geboortedatum', '>', currentDate));

    const querySnapshot = await getDocs(verjaardag );
    const list: RemoteTalent[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push({
            ref: doc.ref,
            achternaam : doc.data().achternaam,
                  data_eigenaar_ref : doc.data().data_eigenaar_ref,
                  email :doc.data().email,
                  gebruikers_rol : doc.data().gebruikers_rol,
                  geslacht : doc.data().geslacht,
                  profiel_foto : doc.data().profiel_foto,
                  telefoon_nummer : doc.data().telefoon_nummer,
                  talent : doc.data().talent,
                  voornaam : doc.data().voornaam, 
        })
    });

    return list; 

}

export const getTalentenWithUitstroom = async () => {
    const q = query(collection(db, "user"), where("gebruikers_rol", "==", "talent"), where("talent.uitstroom", "==", true));

    const querySnapshot = await getDocs(q);
    const list: RemoteTalent[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push({
            ref: doc.ref,
            achternaam : doc.data().achternaam,
                  data_eigenaar_ref : doc.data().data_eigenaar_ref,
                  email :doc.data().email,
                  gebruikers_rol : doc.data().gebruikers_rol,
                  geslacht : doc.data().geslacht,
                  profiel_foto : doc.data().profiel_foto,
                  telefoon_nummer : doc.data().telefoon_nummer,
                  talent : doc.data().talent,
                  voornaam : doc.data().voornaam, 
        })
    });

    return list; 

}

export const getGevoel = async () => {
    const gevoel = query(collection(db, 'mood_talent'), where('gevoel', '<=', 3))
}

//For the filter function
 export const getTalentDocumentsByTraject = async (reference: DocumentReference) => {

    const q = query(collection(db, 'user'), where('talent.traject_ref', '==', reference), orderBy("voornaam"))
    const trajectQuery = await getDocs(q);

    const list: RemoteTalent[] =  [] 
    trajectQuery.forEach((doc) => {
        list.push({
            ref: doc.ref,
            achternaam : doc.data().achternaam,
                  data_eigenaar_ref : doc.data().data_eigenaar_ref,
                  email :doc.data().email,
                  gebruikers_rol : doc.data().gebruikers_rol,
                  geslacht : doc.data().geslacht,
                  profiel_foto : doc.data().profiel_foto,
                  telefoon_nummer : doc.data().telefoon_nummer,
                  talent : doc.data().talent,
                  voornaam : doc.data().voornaam, 
        })
    });

    return list; 
}

export const getCountTalentenByTraject = async (reference: DocumentReference) => {
    const q = query(collection(db, "user"), where("gebruikers_rol", "==", "talent"), where("talent.traject_ref", "==", reference));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count
}

